import React, { useState } from 'react';
import { useQuery } from 'shared/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { Creators, updateUser, updateUserImage, passwordUpdate } from 'store/ducks/user';
import { getI18n, useTranslation } from 'react-i18next';
import { Button, Input, Avatar, Spinner } from 'components/template';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import styles from './PrimeiroAcesso.module.scss';
import LanguageButton from 'App/Layout/AppBar/LanguageButton/LanguageButton';
import Logo from 'assets/footlink-primeiro-acesso.svg';
import AtletaPNG from 'assets/atleta-primeiro-acesso.png';
import FormPerfil from './FormPerfil/FormPerfil';
import { Link, useParams, Redirect } from 'react-router-dom';
import CountDownTimer from './CountDownTimer/CountDownTimer';
import { sendCode, validateCode } from 'store/ducks/firstAccess';
import { getUnique } from 'shared/utility';
import { getCountries } from 'store/ducks/countries';
import { useLocation } from 'react-router-dom';

const UI = {
  FIRSTPAGE: 'FIRSTPAGE',
  SECPAGE: 'SECPAGE',
  THRDPAGE: 'THRDPAGE',
  FOURPAGE: 'FOURPAGE',
  FIFPAGE: 'FIFPAGE',
  SIXPAGE: 'SIXPAGE',
};

const initialForm = {
  email: '',
  password: '',
  code: '',
  failIfAlreadyLogged: true,
  hasAnyError: false,
  isLoading: false,
};

const initialFormUser = {
  name: '',
  phoneddi: '+55',
  phoneddd: '',
  phonenumber: '',
  whatsappddi: '+55',
  whatsappddd: '',
  whatsappnumber: '',
  role: '',
};

const initialFormPassword = {
  current_password: '',
  new_password: '',
  re_new_password: '',
};

export default function PrimeiroAcesso() {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [formLogin, setFormLogin] = useState(initialForm);
  const [formUser, setFormUser] = useState(initialFormUser);
  const [formPassword, setFormPassword] = useState(initialFormPassword);
  const [imgFile, setImgFile] = useState(null);
  const [savingUser, setSavingUser] = useState(false);
  const [formUserErrorMessage, setFormUserErrorMessage] = useState(null);
  const [ui, setUi] = useState(UI.FIRSTPAGE);
  const [isPwdVisible, setPwdVisibility] = useState(false);
  const { token } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const keyValue = queryParams.get('key');

  const countries = useSelector(state => ({
    data: getUnique(state.countries.countries.data, 'ddi').filter(country => country.ddi !== ''),
    loading: state.countries.countries.loading,
    error: state.countries.countries.error,
  }));

  const currentLanguage = getI18n().language;
  const queryLanguage = useQuery().get('language');
  if (queryLanguage === 'pt-br' && currentLanguage !== 'br') {
    i18n.changeLanguage('br');
    localStorage.setItem('language', 'br');
  } else if (queryLanguage === 'en-us' && currentLanguage !== 'en') {
    i18n.changeLanguage('en');
    localStorage.setItem('language', 'en');
  } else if (queryLanguage === 'es-es' && currentLanguage !== 'esp') {
    i18n.changeLanguage('esp');
    localStorage.setItem('language', 'esp');
  }

  if (user.data != null) {
    if (formLogin.email === '') {
      return <Redirect to="/home" />;
    }
    if (ui === UI.THRDPAGE && !user.login.loading && formLogin.isLoading) {
      const hasError = user.login.error != null;
      setFormLogin({
        ...formLogin,
        hasAnyError: hasError,
        isLoading: false,
      });
      if (!hasError) {
        setUi(UI.FOURPAGE);
      }
    }
  }

  const firstPageContainerClasses = [styles.firstPageContainer];
  if (ui === UI.FIRSTPAGE) firstPageContainerClasses.push(styles.active);

  const secondPageContainerClasses = [styles.secondPageContainer];
  if (ui === UI.SECPAGE) secondPageContainerClasses.push(styles.active);

  const thirdPageContainerClasses = [styles.thirdPageContainer];
  if (ui === UI.THRDPAGE) thirdPageContainerClasses.push(styles.active);

  const fourthPageContainerClasses = [styles.fourthPageContainer];
  if (ui === UI.FOURPAGE) fourthPageContainerClasses.push(styles.active);

  const fifthPageContainerClasses = [styles.fifthPageContainer];
  if (ui === UI.FIFPAGE) fifthPageContainerClasses.push(styles.active);

  const sixthPageContainerClasses = [styles.sixthPageContainer];
  if (ui === UI.SIXPAGE) sixthPageContainerClasses.push(styles.active);

  const passwordIcon = isPwdVisible ? (
    <FaEye className={styles.icon} onClick={() => setPwdVisibility(false)} />
  ) : (
    <FaEyeSlash className={styles.icon} onClick={() => setPwdVisibility(true)} />
  );

  const validarFormUser = values => {
    return (
      values.name !== '' &&
      values.role !== '' &&
      values.phoneddd !== '' &&
      values.phoneddi !== '' &&
      values.phonenumber !== '' &&
      values.whatsappddd !== '' &&
      values.whatsappddi !== '' &&
      values.whatsappnumber !== ''
    );
  };

  const validarPassword = values => {
    const regex = /\W|_/;
    return (
      values.new_password !== '' &&
      values.new_password.length >= 8 &&
      regex.test(values.new_password) &&
      values.new_password === values.re_new_password
    );
  };

  const handleChangeFormUser = e => {
    if (e.name === 'phoneddi') {
      setFormUser({ ...formUser, phoneddi: e.value });
    } else if (e.name === 'whatsappddi') {
      setFormUser({ ...formUser, whatsappddi: e.value });
    } else setFormUser({ ...formUser, [e.target.name]: e.target.value });
  };

  const handleChangeFormPassword = e => {
    setFormPassword({ ...formPassword, [e.target.name]: e.target.value, current_password: formLogin.password });
  };

  async function handleSendFormUser() {
    const resultValidateUser = await validarFormUser(formUser);
    if (!resultValidateUser) {
      setFormUserErrorMessage('firstAccess.errorUser');
      return;
    }

    const resultValidatePassword = await validarPassword(formPassword);
    if (!resultValidatePassword) {
      setFormUserErrorMessage('firstAccess.errorPassword');
      return;
    }

    if (imgFile) {
      dispatch(updateUserImage(user?.data?.id, imgFile));
    }

    const userData = {
      name: formUser.name,
      profile: {
        occupation: formUser.role,
        phones: {
          phone1: {
            ddi: formUser.phoneddi,
            ddd: formUser.phoneddd,
            number: formUser.phonenumber,
          },
          whatsapp: {
            ddi: formUser.whatsappddi,
            ddd: formUser.whatsappddd,
            number: formUser.whatsappnumber,
          },
        },
      },
    };
    dispatch(updateUser(user?.data?.id, userData));
    dispatch(passwordUpdate(formPassword));
    setSavingUser(true);
  }

  function handleCallBackend() {
    dispatch(getCountries());
    setUi(UI.FIFPAGE);
  }

  const firstPage = (
    <>
      <h1 className={styles.title}>
        {t('firstAccess.text1')},
        <br />
        {t('firstAccess.text2')} <span>{t('firstAccess.text3')}</span>
        <br />
        {t('firstAccess.text4')}
      </h1>
      <div className={styles.subTitle}>
        <p className={styles.text1}>{t('firstAccess.text5')}</p>
        <p className={styles.text2}>{t('firstAccess.text6')}</p>
        <p className={styles.text3}>{t('firstAccess.text7')}</p>
        <div className={styles.buttonContainer}>
          <Button
            rounded
            color="green"
            disabled={formLogin.isLoading}
            onClick={async () => {
              setFormLogin({
                ...formLogin,
                hasAnyError: false,
                isLoading: true,
              });
              const email = await sendCode(token, keyValue);
              setFormLogin({
                ...formLogin,
                email: email ?? '',
                hasAnyError: email == null,
                isLoading: false,
              });
              if (email != null) {
                setUi(UI.THRDPAGE);
              }
            }}
          >
            {t('firstAccess.text8')}
          </Button>
        </div>
        {formLogin.hasAnyError && <span className={styles.tokenError}>{t('firstAccess.tokenError')}</span>}
      </div>
    </>
  );

  const secondPage = (
    <>
      <h1 className={styles.title}>
        {t('firstAccess.text9')},
        <br />
        {t('firstAccess.text10')}
        <span>{t('firstAccess.text11')}</span>
        {t('firstAccess.text12')}
        <br />
        {t('firstAccess.text13')}
      </h1>
      <div className={styles.content}>
        <div>
          <Input
            label={<div className={styles.passwordLabel}>{t('firstAccess.text14')}</div>}
            type={isPwdVisible ? 'text' : 'password'}
            containerClassName={styles.inputContainer}
            value={formLogin.password}
            icon={passwordIcon}
            iconPosition="right"
            onChange={e => setFormLogin({ ...formLogin, password: e.target.value })}
            hasError={formLogin.hasAnyError}
            inputClassName={styles.passwordInput}
          />
          {formLogin.hasAnyError && <span className={styles.passwordError}>{t('firstAccess.text21')}</span>}
        </div>
        <Button
          rounded
          color="green"
          disabled={formLogin.password === '' || formLogin.isLoading}
          style={{ cursor: formLogin.isLoading ? 'wait' : 'pointer' }}
          onClick={async () => {
            setFormLogin({
              ...formLogin,
              hasAnyError: false,
              isLoading: true,
            });
            const email = await sendCode(token, formLogin.password);
            setFormLogin({
              ...formLogin,
              email: email ?? '',
              hasAnyError: email == null,
              isLoading: false,
            });
            if (email != null) {
              setUi(UI.THRDPAGE);
            }
          }}
        >
          {t('firstAccess.text15')}
        </Button>
      </div>
    </>
  );

  const thirdPage = (
    <>
      <h1 className={styles.title}>
        {t('firstAccess.text16')}
        <br />
        {t('firstAccess.text17')}
        <br />
        <span>
          {formLogin.email.substring(0, 2)}******@{formLogin.email.split('@')[1]}
        </span>
      </h1>
      <div className={styles.content}>
        <Input
          label={t('firstAccess.text18')}
          inputStyle={{ width: 220 }}
          hasError={formLogin.hasAnyError}
          onChange={e =>
            setFormLogin({
              ...formLogin,
              code: e.target.value,
            })
          }
        />
        <Button
          rounded
          color="green"
          disabled={formLogin.code === '' || formLogin.isLoading}
          style={{ cursor: formLogin.isLoading ? 'wait' : 'pointer' }}
          onClick={async () => {
            setFormLogin({
              ...formLogin,
              hasAnyError: false,
              isLoading: true,
            });
            const success = await validateCode(token, formLogin.code);
            if (success) {
              dispatch(Creators.login(formLogin.email, formLogin.password, true, false));
            } else {
              setFormLogin({
                ...formLogin,
                hasAnyError: true,
              });
            }
          }}
        >
          {t('firstAccess.text15')}
        </Button>
        {ui === UI.THRDPAGE && <CountDownTimer min={5} sec={0} onRequest={() => sendCode(token, formLogin.password)} />}
      </div>
    </>
  );

  const fourthPage = (
    <>
      <h1 className={styles.title}>{t('firstAccess.box2')}</h1>
      <p>
        {t('firstAccess.box3')}
        <br />
        {t('firstAccess.box4')}
      </p>
      <div className={styles.content}>
        <div className={styles.content}>
          <div className={styles.teamContainer}>
            <div className={styles.conteudo}>
              <Avatar
                noBorder
                noRadius
                img={user?.data?.organization?.clubImg ?? user?.data?.organization?.id?.emblem ?? ''}
                name={user?.data?.organization?.shortName ?? user?.data?.organization?.id?.nickname}
                className={styles.club_avatar}
              />
              <div className={styles.clubInfo}>
                <h1>{user?.data?.organization?.shortName ?? user?.data?.organization?.id?.nickname}</h1>
                <span>{user?.data?.organization?.id?.longname ?? ''}</span>
                {user?.data?.organization?.countryImg && (
                  <div className={styles.address}>
                    <img
                      draggable="false"
                      src={user?.data?.organization?.countryImg}
                      alt="país do clube"
                      className={styles.country_flag}
                    />
                    <span>{user?.data?.organization?.city}</span>
                  </div>
                )}
              </div>
              <span className={styles.separator}></span>
            </div>
          </div>
          <Button rounded color="green" onClick={() => handleCallBackend()}>
            {t('firstAccess.box5')}
          </Button>
          <p className={styles.contactUs}>
            {t('firstAccess.box6')}
            <span onClick={() => window.open('https://wa.me/5551991963928', '_blank')}>{t('firstAccess.box7')}</span>.
          </p>
        </div>
      </div>
    </>
  );

  const fifthPageIsLoading = countries?.loading || user?.profile?.loading || user?.password?.loading;
  const fifthPage = (
    <>
      <h1 className={styles.title}>{t('firstAccess.box8')}</h1>
      <p>{t('firstAccess.box9')}</p>
      {fifthPageIsLoading ? (
        <div style={{ padding: '30vh 0' }}>
          <Spinner size="50px" />
        </div>
      ) : (
        <>
          <FormPerfil
            userName={formUser.name}
            userEmail={user?.profile?.data?.email || formLogin.email || ''}
            countries={countries}
            onChangeFormUser={handleChangeFormUser}
            onChangeFormPassword={handleChangeFormPassword}
            onUpdateFile={file => setImgFile(file)}
            errorMessage={formUserErrorMessage}
          />
          <div className={styles.createProfile}>
            <Button rounded color="green" onClick={() => handleSendFormUser()}>
              {t('firstAccess.box10')}
            </Button>
          </div>
        </>
      )}
    </>
  );

  if (savingUser && !fifthPageIsLoading) {
    setSavingUser(false);
    setUi(UI.SIXPAGE);
  }

  const sixthPage = (
    <>
      <h1 className={styles.title}>
        {t('firstAccess.box11')}
        <span>{t('firstAccess.box18')}</span>!
      </h1>
      <p className={styles.sixPageSubTitle}>
        {t('firstAccess.box12')}
        <br />
        {t('firstAccess.box19')}
      </p>
      <p className={styles.sixPageText}>
        {t('firstAccess.box13')}
        <br />
        {t('firstAccess.box20')}
      </p>
      <div className={styles.content}>
        <Button rounded color="green" onClick={() => (window.location.href = '/login')}>
          {t('firstAccess.box14')}
        </Button>
      </div>
    </>
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.footlinkLogo}>
            <img src={Logo} alt="Logotipo Footlink" width="199" />
          </div>
          {ui === UI.SIXPAGE ? (
            <div className={styles.videoSixPage}>
              <p>{t('firstAccess.box15')}</p>
              <div className={styles.videoContainer}>
                <img
                  src="https://storage.googleapis.com/rdf/uploads/athletes/avatar/540413/1692380210713.jpeg"
                  alt="apresentation video thumbnail"
                  width="500"
                  height="271"
                  onClick={() => window.open('https://streamyard.com/watch/dWYTsinDT27r', '_blank')}
                />
              </div>
            </div>
          ) : (
            <>
              <div className={styles.athleteImg}>
                <img src={AtletaPNG} alt="" />
              </div>
            </>
          )}
        </div>
        <div className={styles.rigthContainer}>
          <div className={styles.languageButton}>
            {ui !== UI.FIFPAGE && <LanguageButton className={styles.containerLanguageButton} />}
            {ui === UI.FIRSTPAGE ? (
              <div className={styles.btnLogin}>
                <p>{t('firstAccess.box16')}</p>
                <Link to="/login">
                  <Button rounded color="green">
                    {t('firstAccess.box17')}
                  </Button>
                </Link>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.cardContainer}>
              <div className={firstPageContainerClasses.join(' ')}>{firstPage}</div>
              <div className={secondPageContainerClasses.join(' ')}>{secondPage}</div>
              <div className={thirdPageContainerClasses.join(' ')}>{thirdPage}</div>
              <div className={fourthPageContainerClasses.join(' ')}>{fourthPage}</div>
              <div className={fifthPageContainerClasses.join(' ')}>{fifthPage}</div>
              <div className={sixthPageContainerClasses.join(' ')}>{sixthPage}</div>
            </div>
          </div>
        </div>
        {/* <Toast type={toast.type} isVisible={toast.isVisible} onClose={resetToast} content={toast.content} /> */}
      </div>
    </>
  );
}
