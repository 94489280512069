import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import styles from './ResultItem.module.scss';

import { AthleteAvatar, Avatar } from 'components/template';
import { useTranslation } from 'react-i18next';

function ResultItem({ title, amount, items, type, history, hideCard, redirectAthletes, clubs }) {
  const user = useSelector(state => state.user.data);
  function handleItemClick(id) {
    const url = type === 'teams' ? '/clube' : '/atletas';
    history.push({ pathname: `${url}/${id}`, state: { previews: window.location.pathname } });
    hideCard();
  }
  const { t } = useTranslation();
  const itemsContent =
    items &&
    items.map(item => {
      let address = '';
      if (item.state) address = item.state;
      if (item.country && item.country.name !== '') {
        if (address !== '') address = address + ', ' + item.country.name;
        else address = item.country.name;
      }

      return (
        <div onClick={() => handleItemClick(item.id)} key={item.id} className={styles.resultItem}>
          {type === 'teams' ? (
            <Avatar noBorder img={item.emblem && item.emblem} className={styles.avatarDiv} />
          ) : (
            <AthleteAvatar img={item.photo} clubImg={item.team_emblem} className={styles.avatarDiv} />
          )}
          <div className={styles.generalInfo}>
            <h5>{item.nickname}</h5>
            <span>{type === 'teams' ? item.longname : item.fullname}</span>
          </div>
          <div className={styles.rightInfo}>
            {type !== 'teams' && item.birthyear && (
              <span>
                '{item.birthyear.toString().substr(-2)} ({item.age} {t('athlete_card.idade')})
              </span>
            )}
            {type === 'teams'
              ? address && <span>{address}</span>
              : item.position && <span>{t(`positionOptionsRaw.${item.position}`)}</span>}
            {type === 'teams'
              ? item.country && <img width="25" src={item.country?.flag} alt={item.country?.name ?? ''} />
              : item.nationalities?.length > 0 && (
                  <span className={styles.flags}>
                    {item.nationalities.map(i => (
                      <img width="25" key={i.name} src={i.flag} alt={i.name} />
                    ))}
                  </span>
                )}
          </div>
        </div>
      );
    });

  let amount_text = t('app.box6');
  if (amount > 0) {
    amount_text += ` (${amount})`;
  }
  return (
    <>
      <div className={styles.title}>
        <h3>{title}</h3>
        {type === 'teams' ? (
          <Link
            to={user.permission_gender !== 'female' ? '/clubes-brasileiros/serie-a' : '/clubes-brasileiros/todos'}
            style={{ textDecoration: 'none' }}
          >
            <span>{`${t('app.box6')} (${clubs})`}</span>
          </Link>
        ) : (
          <span onClick={() => redirectAthletes(type)}>{amount_text}</span>
        )}
      </div>
      {itemsContent}
    </>
  );
}

ResultItem.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  type: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  hideCard: PropTypes.func.isRequired,
  redirectAthletes: PropTypes.func.isRequired,
};

export default withRouter(ResultItem);
