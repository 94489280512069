import React, { useEffect, useState } from 'react';
import { withRouter, Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import styles from './NationalClubs.module.scss';

import ActionBar from './ActionBar/ActionBar';
import { brazilianStates } from 'shared/mocks';
import TopBar from './TopBar/TopBar';
import ClubsList from './ClubsList/ClubsList';
import { useTranslation } from 'react-i18next';

function NationalClubs() {
  const { t } = useTranslation();
  const baseURL = `/clubes-brasileiros`;
  const location = useLocation();
  const history = useHistory();
  const [searchName, setSearchName] = useState('');
  const [filterMenu, setFilterMenu] = useState({
    currentState: [],
  });
  const user = useSelector(state => state.user.data);

  useEffect(() => {
    if (user.permission_gender === 'female') {
      const restrictedPaths = [
        '/clubes-brasileiros/serie-a',
        '/clubes-brasileiros/serie-b',
        '/clubes-brasileiros/serie-c',
      ];

      if (restrictedPaths.includes(location.pathname)) {
        history.push('/clubes-brasileiros/todos');
      }
    }
  }, [location, history, user]);

  const items = [
    {
      label: t('home.text6'),
      link: `${baseURL}/serie-a`,
      className: `${styles.menuInactive} ${styles.menuFirstChild} `,
      isActive: location.pathname.includes(`${baseURL}/serie-a`),
      component: <ClubsList division={1} filterMenu={filterMenu} searchName={searchName} />,
    },
    {
      label: t('home.text7'),
      link: `${baseURL}/serie-b`,
      className: `${styles.menuInactive} `,
      isActive: location.pathname.includes(`${baseURL}/serie-b`),
      component: <ClubsList division={2} filterMenu={filterMenu} searchName={searchName} />,
    },
    {
      label: t('home.text8'),
      link: `${baseURL}/serie-c`,
      className: `${styles.menuInactive} `,
      isActive: location.pathname.includes(`${baseURL}/serie-c`),
      component: <ClubsList division={3} filterMenu={filterMenu} searchName={searchName} />,
    },
    {
      label: t('home.text9'),
      link: `${baseURL}/serie-d`,
      className: `${styles.menuInactive} `,
      isActive: location.pathname.includes(`${baseURL}/serie-d`),
      component: <ClubsList division={4} filterMenu={filterMenu} searchName={searchName} />,
    },
    {
      label: t('home.text5'),
      link: `${baseURL}/todos`,
      className: `${styles.menuInactive} ${styles.menuLastChild} `,
      isActive: location.pathname.includes(`${baseURL}/todos`),
      component: <ClubsList filterMenu={filterMenu} searchName={searchName} />,
    },
  ];

  const findIsActive = items.find(item => item.isActive);

  if (findIsActive != null) findIsActive.className += styles.menuActive;

  const routes = items.map(item => <Route key={item.label} exact path={item.link} render={() => item.component} />);
  const page = {
    title: t('home.text4'),
    subTitle: t('trello.box5'),
  };

  let content = null;
  content = (
    <>
      <TopBar />
      <div className={styles.content}>
        <div>
          <h1 className={styles.title}>{page.title}</h1>
          <span className={styles.subTitle}>{page.subTitle}</span>
        </div>
        <div className={styles.actionBar}>
          {user.permission_gender !== 'female' && <NavigationBar filled={true} items={items} />}
          <div className={styles.actionBar}>
            <ActionBar
              stateOptions={brazilianStates}
              filterMenu={filterMenu}
              setFilterMenu={setFilterMenu}
              nameInputChangeHandler={setSearchName}
              nameInputValue={searchName}
            />
          </div>
        </div>
        <Switch>
          {routes}
          <Redirect to={baseURL} />
        </Switch>
      </div>
    </>
  );

  return <div className={styles.container}>{content}</div>;
}

export default withRouter(NationalClubs);
