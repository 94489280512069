import React, { useState, useEffect } from 'react';
import { Spinner, Table, Pagination, ExcededPlanLimit } from 'components/template';
import { useTranslation } from 'react-i18next';
import { getFilteredAthletes } from 'store/ducks/transferAthletes';
import { useDispatch, useSelector } from 'react-redux';
import { usePagination } from 'shared/hooks';
import { Link, useLocation } from 'react-router-dom';
import styles from './AvailableAthletes.module.scss';
import TeamRow from 'components/TeamRow/TeamRow';
import AthleteRow from 'components/AthleteRow/AthleteRow';
import NationalitiesRow from 'components/NationalitiesRow/NationalitiesRow';
import ActiveFilters from './ActiveFilters/ActiveFilters';
import Filter from './Filter/Filter';
import moment from 'moment';
import TransferMarketBlur from 'assets/transfer_market_blur.png';

export default function AvailableAthletes() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const athletes = useSelector(state => state.transferAthletes);
  const countries = useSelector(state => state.countries.countries);
  const loggedUser = useSelector(state => state.user.data);

  const [pagination, setPagination, defaultPagination] = usePagination();
  const [queryFilters, setQueryFilters] = useState(location?.state?.queryFilters || null);
  const [activeFilters, setActiveFilters] = useState({});

  // prettier-ignore
  const positionMap = {
    'Atacante': t('positionOptions.Atacante'),
    'Ataque': t('positionOptions.Ataque'),
    'Centroavante': t('positionOptions.Centroavante'),
    'Zagueiro': t('positionOptions.Zagueiro'),
    'Defesa': t('positionOptions.Defesa'),
    'Goleiro': t('positionOptions.Goleiro'),
    'Meia': t('positionOptions.Meia'),
    'Volante': t('positionOptions.Volante'),
    'Lateral Direito': t('positionOptions.LateralDireito'),
    'Lateral Esquerdo': t('positionOptions.LateralEsquerdo'),
    'Meia-atacante': t('positionOptions.MeiaAtacante'),
    'Meio-campo': t('positionOptions.MeioCampo'),
  };

  useEffect(() => {
    if (loggedUser.plan.mercado_de_transferencias) {
      setPagination(defaultPagination);
    }
    dispatch(getFilteredAthletes({ ...queryFilters, ...defaultPagination }));
  }, [dispatch, queryFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActiveFilters({});
  }, []);

  const onSetQueryFilters = filters => {
    let emptyObj;

    for (let filter in filters) {
      if (Array.isArray(filters[filter]) && filters[filter].length === 0) {
        delete filters[filter];
      } else if (typeof filters[filter] === 'object') {
        emptyObj = true;

        for (let key in filters[filter]) {
          if (filters[filter][key] !== '') {
            emptyObj = false;
          }
        }

        if (emptyObj) {
          delete filters[filter];
        }
      } else if (filters[filter] === '') {
        delete filters[filter];
      } else if (filter === 'watched' && !filters[filter]) {
        delete filters[filter];
      }
    }

    setActiveFilters(filters);
  };

  const handlePagination = newPagination => {
    if (loggedUser.plan.mercado_de_transferencias) {
      setPagination(newPagination);
    }
    dispatch(getFilteredAthletes({ ...queryFilters, ...newPagination }));
  };

  let tableData = [];
  if (athletes.data) {
    athletes.data.forEach(row => {
      if (row.transfermarket) {
        row.transfermarket.forEach(transfermarket => {
          const announced = moment(transfermarket.created_at).format('DD/MM/YYYY');
          const athlete = <AthleteRow name={row.fullname} nickname={row.nickname} img={row.photo} id={row.id} />;
          const club = (
            <TeamRow
              name={row.current_team?.nickname ?? row.agency?.name}
              img={row.current_team?.emblem ?? row.agency?.emblem}
            />
          );
          const age = row.age;
          const position = positionMap[row.position];
          const nac = (
            <span className={styles.flag}>
              <NationalitiesRow nationalities={row.nationalities} athleteId={row.id} />
            </span>
          );
          const business = t(`negotiationOptions.${transfermarket.negotiation_type}`);
          const moreInfo = (
            <Link to={`/more-info/${transfermarket.id}`}>
              {' '}
              <div className={styles.moreInfo}>{t('negotiations.moreInfo')}</div>{' '}
            </Link>
          );

          tableData.push([announced, athlete, club, age, position, nac, business, moreInfo]);
        });
      }
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <Filter
          athletes={athletes}
          setQueryFilters={setQueryFilters}
          onSetQueryFilters={onSetQueryFilters}
          activeFilters={activeFilters}
          overlay={athletes.loading}
          className={styles.filter}
          autoSearch={true}
        />
      </div>
      <div className={styles.right} style={loggedUser.plan.mercado_de_transferencias ? {} : { bottom: 0 }}>
        {athletes.loading && (
          <div className={styles.loader}>
            <Spinner />
          </div>
        )}
        {athletes.error && 'erro'}
        {athletes.data && (
          <>
            <div className={styles.header}>
              <h1>{t('club.box28')}</h1>
              <span>{t('transferMarket.subtitle')}</span>
            </div>
            <div className={styles.activeFilters}>
              <ActiveFilters
                setActiveFilters={setActiveFilters}
                activeFilters={activeFilters}
                countries={countries.data}
                overlay={athletes.loading}
              />
            </div>
            <div className={styles.athleteList}>
              <span className={styles.availableAthletes}>
                <strong>{athletes?.count ?? 0}</strong>
                {t('transferMarket.available_negotiation')}
              </span>
              {loggedUser.plan.mercado_de_transferencias ? (
                <>
                  <Table
                    theads={[
                      t('transferMarket.announcedIn'),
                      t('table.atleta'),
                      t('transferMarket.headerClubTitle'),
                      t('filters.age'),
                      t('athlete_card.pos'),
                      'Nac.',
                      t('transferMarket.business'),
                      '',
                    ]}
                    data={tableData}
                    className={styles.athletesTable}
                  />
                  <Pagination
                    length={athletes.data.length}
                    left={315}
                    hasNext={athletes.hasNext}
                    hasPrevious={athletes.hasPrevious}
                    pagination={pagination}
                    count={athletes?.count ?? 0}
                    setPagination={handlePagination}
                    resourceDisplayName={t('filters.ath')}
                  />
                </>
              ) : (
                <>
                  <img draggable="false" className={styles.brandLogo} src={TransferMarketBlur} alt="Transfer Market" />
                  <div className={styles.excededPlanLimit}>
                    <ExcededPlanLimit showBackground={false} />
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
