import Endpoints from 'endpoints';
import axios from 'api';
import ErrorMessages from 'shared/ErrorMessages';

export const actionTypes = {
  GET_ORIGINALS: 'originals/GET',
  GET_ORIGINALS_ERROR: 'originals/GET_ERROR',
  LIKE_SUCCESS: 'originals/LIKE_SUCCESS',
  LIKE_FAIL: 'originals/LIKE_FAIL',
};

const initialState = {
  originals: {
    id: null,
    like: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ORIGINALS:
      return {
        ...state,
        originals: {
          ...state.originals,
          ...action.payload,
        },
      };
    case actionTypes.GET_ORIGINALS_FAIL:
      return {
        ...state,
        originals: {
          ...state.originals,
          error: action.payload.error,
          like: false,
        },
      };
    case actionTypes.LIKE_SUCCESS:
      return {
        ...state,
        originals: {
          ...state.originals,
          ...action.payload,
        },
      };
    case actionTypes.LIKE_FAIL:
      return {
        ...state,
        originals: {
          ...state.originals,
          like: false,
        },
      };
    default:
      return state;
  }
}

export const getOriginals = () => async dispatch => {
  dispatch({ type: actionTypes.GET_ORIGINALS });
  try {
    const { data } = await axios.get(`${Endpoints.videoAccess}`);
    dispatch({
      type: actionTypes.GET_ORIGINALS,
      payload: {
        id: data.results[0].id,
        like: data.results[0].is_liked,
      },
    });
  } catch (error) {
    const { response } = error;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.GET_ORIGINALS_FAIL,
        payload: { error: 'erros.text39' },
      });
      throw new Error('erros.text39');
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.GET_ORIGINALS_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
      throw new Error(ErrorMessages.defaultCredentialsError);
    } else {
      dispatch({
        type: actionTypes.GET_ORIGINALS_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
      throw new Error(ErrorMessages.serviceUnavailable);
    }
  }
};

export const likeVideo = (id, liked) => async dispatch => {
  dispatch({ type: actionTypes.LIKE_SUCCESS });
  try {
    const { data } = await axios.patch(`${Endpoints.videoAccess}/${id}`, { is_liked: !liked });
    dispatch({ type: actionTypes.LIKE_SUCCESS, payload: { id: data.id, like: data.is_liked } });
  } catch (error) {
    const { response } = error;

    if (response?.status === 404) {
      dispatch({
        type: actionTypes.LIKE_FAIL,
        payload: { error: 'erros.text39' },
      });
      throw new Error('erros.text39');
    } else if (response?.status === 401) {
      dispatch({
        type: actionTypes.LIKE_FAIL,
        payload: { error: ErrorMessages.defaultCredentialsError },
      });
      throw new Error(ErrorMessages.defaultCredentialsError);
    } else {
      dispatch({
        type: actionTypes.LIKE_FAIL,
        payload: { error: ErrorMessages.serviceUnavailable },
      });
      throw new Error(ErrorMessages.serviceUnavailable);
    }
  }
};
