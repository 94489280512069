// react
import React from 'react';
import PropTypes from 'prop-types';

// hooks
import { useSelector } from 'react-redux';

// scss
import styles from './AppBar.module.scss';

// icons
import Logo from 'assets/logo.svg';
import { ReactComponent as Menu } from './menu.svg';
import { IoHelp } from 'react-icons/io5';

// components
import { Link } from 'react-router-dom';
import LanguageButton from './LanguageButton/LanguageButton';
import SearchBar from './SearchBar/SearchBar';
import ChatIcon from './ChatIcon/ChatIcon';
import ProfileIcon from './ProfileIcon/ProfileIcon';
import { useTranslation } from 'react-i18next';
import GenderProfileButton from './GenderProfileButton/GenderProfileButton';
// import MonitoringIcon from './MonitoringIcon/MonitoringIcon';
// import TransferWindowsIcon from './TransferWindowsIcon/TransferWindowsIcon';
// import NotificationsIcon from './NotificationsIcon/NotificationsIcon';

export default function AppBar({ toggleNavDrawer, darkMode }) {
  const { t } = useTranslation();
  const user = useSelector(state => state.user.data);
  const notifications = useSelector(state => state.notifications);
  const logo = <img draggable="false" className={styles.brandLogo} src={Logo} alt="Logo Footlink" width="128" />;

  const newTransferMarketMessages = notifications?.data?.newTransferMarketMessages ?? false;
  return (
    <nav className={`${styles.appBar} ${darkMode ? styles.darkAppBar : ''}`}>
      <div className={styles.leftItems}>
        {user.is_only_for_projects ? (
          <Link to="/">{logo}</Link>
        ) : (
          <>
            <div onClick={toggleNavDrawer} className={styles.barMenu}>
              <Menu />
              {newTransferMarketMessages && <div className={styles.menuNotification} />}
            </div>
            <Link to="/">{logo}</Link>
          </>
        )}
      </div>
      <div>
        <GenderProfileButton />
      </div>
      {user.is_only_for_projects ? (
        <div className={`${styles.rightItems} ${styles.reallyRight}`}>
          <ProfileIcon />
          <div
            className={styles.support}
            footlink-title={t('home.support')}
            onClick={() => window.open('https://api.whatsapp.com/send?phone=5551997823228', '_blank')}
          >
            <IoHelp />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.centerItems}>{user.plan.busca_de_atletas && <SearchBar darkMode={darkMode} />}</div>
          <div className={styles.rightItems}>
            <LanguageButton />
            {/*
          <TransferWindowsIcon className={styles.buttonIcons} />
          <MonitoringIcon className={styles.motitoringButton} />
          */}
            {/* <NotificationsIcon className={styles.notifcationButton} /> */}
            {user.plan.mensageria && (
              <ChatIcon className={styles.chatButton} notifications={notifications} darkMode={darkMode} />
            )}
            <ProfileIcon />
            <div
              className={styles.support}
              footlink-title={t('home.support')}
              onClick={() => window.open('https://api.whatsapp.com/send?phone=5551997823228', '_blank')}
            >
              <IoHelp />
            </div>
          </div>
        </>
      )}
    </nav>
  );
}

AppBar.propTypes = {
  toggleNavDrawer: PropTypes.func.isRequired,
};
