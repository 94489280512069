import React, { useEffect } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/competition';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import styles from './Clubs.module.scss';
import TopBar2 from 'components/template/TopBar2/TopBar2';
import CompetitionInfo from 'components/CompetitionInfo/CompetitionInfo';
import ClubsList from './ClubsList/ClubsList';
import Matches from './Matches/Matches';
import Reports from './Reports/Reports';
import { Card, Spinner } from 'components/template';
import AthletesList from './AthletesList/AthletesList';
import PrecociousAthletes from './PrecociousAthletes/PrecociousAthletes';
import Subscribed from '../Subscribed/Subscribed';
import { useTranslation } from 'react-i18next';

function Clubs({ match }) {
  const baseURL = `/base/competicoes/${match.params.id}`;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const competition = useSelector(state => state.competition.competitionsPage);
  const location = useLocation();
  const hideTopBar = location.pathname.includes('inscritos');
  const precociousCategories = ['Profissional', 'Sub-23', 'Sub-20', 'Sub-19', 'Sub-17', 'Sub-15', 'Sub-14', 'Sub-13'];

  useEffect(() => {
    dispatch(Creators.getCompetitionData(match.params.id));
    return () => {
      dispatch(Creators.resetCompetitions());
    };
  }, [dispatch, match.params.id]);

  const rawData = competition.data;
  let items = [];

  if (parseInt(rawData.reports_count) > 0) {
    items.push({
      label: t('compe.text11'),
      link: `${baseURL}/relatorios`,
      component: <Reports id={match.params.id} baseURL={baseURL} />,
    });
  }

  if (rawData?.id) {
    items.push({
      label: t('compe.text12'),
      link: `${baseURL}/clubes`,
      component: <ClubsList id={match.params.id} baseURL={baseURL} />,
    });
    items.push({
      label: t('compe.text13'),
      link: `${baseURL}/artilharia`,
      component: <AthletesList id={match.params.id} />,
    });
  }

  if (parseInt(rawData.matches_count) > 0) {
    items.push({
      label: t('match.text3'),
      link: `${baseURL}/jogos`,
      component: <Matches id={match.params.id} baseURL={baseURL} />,
    });
  }

  if (precociousCategories.includes(rawData.category)) {
    items.push({
      label: t('compe.precociousAthletes'),
      link: `${baseURL}/atletas-precoces`,
      component: <PrecociousAthletes id={match.params.id} season={rawData.season} category={rawData.category} />,
    });
  }

  const routes = items.map(item => <Route key={item.label} exact path={item.link} render={() => item.component} />);

  const topBarStyle = hideTopBar ? { display: 'none' } : {};
  topBarStyle.borderBottom = 'none';

  if (competition.error) {
    return (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(competition.error)}</p>
      </Card>
    );
  }

  return (
    <div className={styles.container}>
      <TopBar2
        img={rawData.emblem}
        title={rawData.nickname}
        pageBack="/base/competicoes"
        style={topBarStyle}
        season={rawData.season}
      />
      <div className={styles.content} style={hideTopBar ? { paddingTop: '0' } : {}}>
        {competition.loading ? (
          <div className={styles.loaderContainer}>
            <Spinner />
          </div>
        ) : (
          <>
            <div className={styles.navigationDiv} style={hideTopBar ? { display: 'none' } : {}}>
              <NavigationBar items={items} className={styles.navigationBar} />
              <CompetitionInfo competition={rawData} />
            </div>
            <Switch>
              {routes}
              <Route path={`${baseURL}/clubes/:teamId/inscritos`} render={() => <Subscribed id={match.params.id} />} />
              <Redirect to={items.length > 0 ? items[0].link : baseURL} />
            </Switch>
          </>
        )}
      </div>
    </div>
  );
}

export default withRouter(Clubs);
