import React, { useState, useEffect, useCallback } from 'react';
import { Menu, MinMaxInput } from 'components/template';
import styles from './CustomInputs.module.scss';
import { useTranslation } from 'react-i18next';

export const SeasonMenu = ({ value, setFilter, onClear }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setSeason(value);
  }, [value]);

  const [season, setSeason] = useState(value);

  const handleChange = newSeason => {
    setSeason(newSeason);
    setFilter(newSeason);
  };

  const maxYear = 10;
  const getOptions = maxYear => {
    const today = new Date();
    let options = [];

    for (let i = 0; i < maxYear; i++) {
      options.push({ value: String(today.getFullYear() - i), label: String(today.getFullYear() - i) });
    }

    return options;
  };

  return (
    <div className={styles.seasonContainer}>
      <Menu
        className={styles.seasonMenu}
        title={t('slider_menu.seas')}
        placeholder={String(season) || t('slider_menu.seas')}
        options={getOptions(maxYear)}
        allowEmpty
        fixedPlaceholder
        setValue={value => handleChange(value)}
        value={season || ''}
        onClear={onClear}
      />
    </div>
  );
};

export const MinMaxDate = ({
  setFilter,
  valueMin,
  valueMax,
  from,
  to,
  title,
  titlePlaceholder,
  onClear,
  noTitle = false,
}) => {
  const { t } = useTranslation();

  const [min, setMin] = useState(valueMin);
  const [max, setMax] = useState(valueMax);

  useEffect(() => {
    setFilter({ min, max });
    // eslint-disable-next-line
  }, [min, max]);

  const getOptions = () => {
    const today = new Date();
    const intialDate = new Date('1980/01/1');
    let options = [];

    for (let i = intialDate.getFullYear(); i < today.getFullYear(); i++) {
      options.push({ value: String(i), label: String(i) });
    }

    return options;
  };

  const options = getOptions();
  const variableMax = String(max) || t('slider_menu.todas');
  const variableMin = String(min) || t('slider_menu.todas');

  return (
    <div>
      {from && (
        <Menu
          className={styles.customMenu}
          label={
            title && !noTitle ? <div className={styles.menuTitle}>{title}</div> : noTitle ? '' : t('slider_menu.de')
          }
          title={title ? <div className={styles.menuTitle}>{title}</div> : t('slider_menu.de')}
          labelClassName={noTitle ? '' : styles.customMenuLabel}
          placeholder={
            titlePlaceholder ? <div className={styles.placeholderTitle}>{titlePlaceholder}</div> : variableMin
          }
          allowEmpty
          value={String(min)}
          options={options}
          setValue={value => setMin(value)}
          onClear={onClear}
        />
      )}
      {to && (
        <Menu
          className={styles.customMenu}
          title={title ? <div className={styles.menuTitle}>{title}</div> : t('slider_menu.ate')}
          label={
            title && !noTitle ? <div className={styles.menuTitle}>{title}</div> : noTitle ? '' : t('slider_menu.ate')
          }
          labelClassName={noTitle ? '' : styles.customMenuLabel}
          placeholder={
            titlePlaceholder ? <div className={styles.placeholderTitle}>{titlePlaceholder}</div> : variableMax
          }
          allowEmpty
          value={String(max)}
          options={options}
          setValue={value => setMax(value)}
          onClear={onClear}
        />
      )}
    </div>
  );
};

export const MinMaxSeason = ({ setFilter, value, onClear, hideSearchBtn = false }) => {
  const [state, setState] = useState(value);
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setState(value);
  }, [value]);

  const handleChange = newFilters => {
    setState(newFilters);
    if (newFilters.season) {
      setFilter(newFilters);
    }
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const debouncedHandleChange = useCallback(debounce(handleChange, 500), [handleChange]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.inputContainer}>
        <SeasonMenu value={state.season} setFilter={season => handleChange({ ...state, season })} onClear={onClear} />
        <MinMaxInput
          state={state}
          setMinMax={minMax => debouncedHandleChange({ ...state, ...minMax })}
          setErrorState={error => setHasError(error)}
          hideSearchBtn={hideSearchBtn}
        />
      </div>
      <p className={styles.errorMessage}>{hasError && t('errorMessage.minMax')}</p>
    </div>
  );
};
