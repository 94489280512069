import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateUser } from 'store/ducks/user';
import styles from './GenderCard.module.scss';
import CheckCircle from 'assets/ico/checkcircle.png';
import { PopCard } from 'components/template';
import { PiGenderFemaleBold, PiGenderMaleBold } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';

function GenderCard({ isVisible, hide }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(state => state.user.data);
  const [checkButton, setCheckButton] = useState(user.permission_gender);

  const masc = 'male';
  const fem = 'female';
  const general = 'general';

  const mascFlag = <PiGenderMaleBold color="#360099" />;
  const femiFlag = <PiGenderFemaleBold color="#c300ff" />;
  const generalFlag = (
    <div className={styles.iconGeneralGender}>
      <PiGenderMaleBold color="#360099" />
      <PiGenderFemaleBold color="#c300ff" />
    </div>
  );
  const check = <img draggable="false" className={styles.checkIcon} src={CheckCircle} alt="Check" />;

  const handleClick = async gender => {
    setCheckButton(gender);

    try {
      dispatch(updateUser(user.id, { permission_gender: gender }));
      const storageData = (await JSON.parse(localStorage.getItem('footlink_udata'))) || {};
      localStorage.setItem(
        'footlink_udata',
        JSON.stringify({
          ...storageData,
          data: { ...storageData.data, permission_gender: gender },
        })
      );
      const sessionData = (await JSON.parse(sessionStorage.getItem('advanced_search'))) || {};
      sessionStorage.setItem(
        'advanced_search',
        JSON.stringify({
          ...sessionData,
          gender: gender === 'female' ? 'female' : 'male',
        })
      );
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {}
  };

  return (
    <PopCard isVisible={isVisible} hide={hide} className={styles.container} style={{ left: '-100px', top: '40px' }}>
      <div onClick={() => handleClick(masc)} className={styles.link}>
        <span className={styles.flag}>{mascFlag}</span>
        <span className={styles.texto}>{t('radioAdvancedSearch.male')}</span>
        <span className={styles.checkIcon}>{checkButton === 'male' && check}</span>
      </div>
      <div onClick={() => handleClick(fem)} className={styles.link}>
        <span className={styles.flag}>{femiFlag}</span>
        <span className={styles.texto}>{t('radioAdvancedSearch.female')}</span>
        <span className={styles.checkIcon}>{checkButton === 'female' && check}</span>
      </div>
      <div onClick={() => handleClick(general)} className={styles.link}>
        <span className={styles.flag}>{generalFlag}</span>
        <span className={styles.texto}>{t('radioAdvancedSearch.male_female')}</span>
        <span className={styles.checkIcon}>{checkButton === 'general' && check}</span>
      </div>
    </PopCard>
  );
}

GenderCard.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
};

export default withRouter(GenderCard);
