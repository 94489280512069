import React, { useState } from 'react';
import { FaSearch, FaClipboardList, FaTrashAlt } from 'react-icons/fa';
import { Input, Menu, SliderMenu, IconButton, Checkbox } from 'components/template';
import styles from './styles.module.scss';
import { getPreferentialFootWithLabel, positionOptions, radioAdvancedSearch } from 'shared/mocks';
import { useTranslation } from 'react-i18next';

export default function ActionBar({ setIsOpenDialog, selectedFilters, setSelectedFilters, list, checkedAthletes }) {
  const { t } = useTranslation();

  const [timeoutId, setTimeoutId] = useState(null);

  const [name, setName] = useState(selectedFilters.name);

  const handleInputChange = value => {
    clearTimeout(timeoutId);
    setName(value);
    setTimeoutId(
      setTimeout(() => {
        setSelectedFilters({ ...selectedFilters, name: value });
      }, 800)
    );
  };

  return (
    <div className={styles.actionBar}>
      <Input
        rounded
        placeholder={t('agencies.athlete_name')}
        containerClassName={styles.inputContainer}
        onChange={e => {
          handleInputChange(e.target.value);
        }}
        icon={<FaSearch className={styles.icon} />}
        iconPosition="right"
        value={name}
      />
      <Menu
        title={t('agencies.lists')}
        largeMenu
        multiple
        rounded
        allowEmpty
        placeholder={t('agencies.lists')}
        fixedPlaceholder
        className={styles.menu}
        options={list ?? []}
        value={selectedFilters.lists}
        onClear={() => {
          setSelectedFilters({ ...selectedFilters, lists: [] });
        }}
        setValue={val => {
          setSelectedFilters({ ...selectedFilters, lists: val });
        }}
      />
      <SliderMenu
        rounded
        title={t('agencies.birth')}
        className={styles.menu}
        max={Number((() => new Date())().getFullYear())}
        min={1975}
        setFilter={val => {
          const [min, max] = val;
          setSelectedFilters({ ...selectedFilters, min_birthyear: min, max_birthyear: max });
        }}
      />
      <Menu
        title={t('athlete_card.pos')}
        largeMenu
        multiple
        rounded
        allowEmpty
        placeholder={t('athlete_card.pos')}
        fixedPlaceholder
        className={styles.menu}
        options={positionOptions.map(item => ({ label: t(`positionOptions.${item.value}`), value: item.label }))}
        value={selectedFilters.position}
        onClear={() => {
          setSelectedFilters({ ...selectedFilters, position: [] });
        }}
        setValue={val => {
          setSelectedFilters({ ...selectedFilters, position: val });
        }}
      />
      <Menu
        title={t('athlete_card.foot')}
        largeMenu
        className={styles.menu}
        allowEmpty
        value={selectedFilters['foot']}
        setValue={foot => setSelectedFilters({ ...selectedFilters, foot: foot })}
        onClear={() => setSelectedFilters({ ...selectedFilters, foot: '' })}
        options={getPreferentialFootWithLabel(t)}
        placeholder={t('athlete_card.foot')}
      />
    </div>
  );
}
