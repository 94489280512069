import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styles from './ClubsList.module.scss';
import { Creators } from 'store/ducks/competition';
import { usePagination } from 'shared/hooks';
import { Card, Spinner, Table, Pagination } from 'components/template';
import TeamRow from 'components/TeamRow/TeamRow';
import CountryRow from 'components/CountryRow/CountryRow';
import { useTranslation } from 'react-i18next';

export default function ClubsList({ id, baseURL }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const teams = useSelector(state => state.competition.teamsPage);

  const [pagination, setPagination] = usePagination();

  useEffect(() => {
    dispatch(Creators.getTeams(id, pagination));
  }, [dispatch, pagination, id]);

  const rawData = teams.data.teams;

  const data =
    rawData &&
    rawData.map(row => {
      const team = (
        <TeamRow name={row.nickname} id={row.id} img={row.emblem} linkExact={`${baseURL}/clubes/${row.id}/inscritos`} />
      );
      const country = <CountryRow name={row.country.name} img={row.country.flag} />;
      const state = row.state || '-';
      const athletesCount = row.athletes_count;
      const averageAge = row.average_age || '-';
      return [team, country, state, athletesCount, averageAge];
    });

  let content = '';
  if (teams.loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (teams.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(teams.error)}</p>
      </Card>
    );
  } else {
    content = (
      <>
        <Card softShadow className={styles.competition}>
          <div className={styles.tableContainer}>
            <Table
              flat
              theads={[
                t('athlete_list.box2'),
                t('compe.text1'),
                t('athlete_card.text5'),
                t('compe.text5'),
                t('compe.text27'),
              ]}
              data={data}
            />
          </div>
        </Card>
        {rawData && teams.data && (
          <Pagination
            length={rawData.length}
            count={teams.data.count}
            pagination={pagination}
            setPagination={setPagination}
            hasNext={teams.data.hasNext}
            hasPrevious={teams.data.hasPrevious}
          />
        )}
      </>
    );
  }

  return <>{content}</>;
}

ClubsList.propTypes = {
  id: PropTypes.string.isRequired,
};
