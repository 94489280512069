import React from 'react';
import styles from './TransferRadar.module.scss';
import { useTranslation } from 'react-i18next';
import { TopBar } from 'components/template';
import RadarSVG from 'assets/radar.svg';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Overview from './Overview/Overview';
import RadarAthletes from './RadarAthletes/RadarAthletes';

function TransferRadar() {
  const { t } = useTranslation();
  const baseURL = `/transfer-radar`;
  const user = useSelector(state => state.user.data);

  const items = [];

  items.push(
    {
      label: 'Visão Geral',
      link: `${baseURL}`,
      component: <Overview />,
    },
    {
      label: 'Atletas no Radar',
      link: `${baseURL}/radar-athletes`,
      component: <RadarAthletes />,
    }
  );

  const routes = items.map(item => <Route key={item.label} exact path={item.link} render={() => item.component} />);

  return (
    <div className={styles.container}>
      <div className={styles.containerFlex}>
        <TopBar
          title="Radar de Transferências"
          subtitle="A ferramenta de Mecanismo de Solidariedade que recupera valores para seu clube"
          icon={<img src={RadarSVG} />}
          className={styles.TopBar}
          iconBgColor={'#F2C94C'}
        />
        <NavigationBar items={items} className={styles.TopNavigation} />
        <div className={styles.content}>
          <Switch>
            {routes}
            <Redirect to={baseURL} />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default withRouter(TransferRadar);
