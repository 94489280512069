/*eslint-disable eqeqeq*/
import React, { useState, useEffect } from 'react';
import { Input, Menu, SelectionButton } from 'components/template';
import { useSelector, useDispatch } from 'react-redux';
import styles from '../Overview.module.scss';
import { useTranslation } from 'react-i18next';
import { getCountries } from 'store/ducks/countries';
import { IoMdSearch } from 'react-icons/io';

export default function ActionBar({ filters, selectedFilters, setSelectedFilters }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const countries = useSelector(state => state.countries.countries);
  const countriesData = countries.data ?? [];
  const user = useSelector(state => state.user.data);

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  const [timeoutId, setTimeoutId] = useState(null);

  const handleInputChange = value => {
    clearTimeout(timeoutId);

    setTimeoutId(
      setTimeout(() => {
        applyFilters({ ...selectedFilters, name_or_organizer_name: value });
      }, 800)
    );
  };

  function applyFilters(newFilters) {
    setSelectedFilters(newFilters);
  }

  const currentCategoryName = selectedFilters.category
    ? filters.categories?.find(c => c.id === selectedFilters.category)?.name
    : null;
  return (
    <div className={styles.filterContainer}>
      <Menu
        title="Por País em que atua"
        rounded
        allowEmpty
        placeholder={selectedFilters.country === '' ? 'Por País em que atua' : selectedFilters.country}
        className={styles.menu}
        options={filters.countries?.map(country => {
          const countryObj = countriesData.find(c => c.code === country);
          return {
            value: country,
            label: countryObj ? countryObj.name : country,
          };
        })}
        setValue={value => applyFilters({ ...selectedFilters, country: value })}
        value={selectedFilters.country}
        onClear={() => applyFilters({ ...selectedFilters, country: '' })}
      />
      <Menu
        title="Por Idade"
        rounded
        allowEmpty
        placeholder={selectedFilters.context === '' ? 'Por Idade' : selectedFilters.context}
        className={styles.menu}
        setValue={value => applyFilters({ ...selectedFilters, context: value })}
        options={filters.context?.map(context => ({ value: context, label: t(`compe.context.${context}`) }))}
        value={selectedFilters.context}
        onClear={() => applyFilters({ ...selectedFilters, context: '' })}
      />
      <Menu
        title="Por Posição"
        rounded
        allowEmpty
        placeholder={selectedFilters.country === '' ? 'Por Posição' : selectedFilters.country}
        className={styles.menu}
        options={filters.countries?.map(country => {
          const countryObj = countriesData.find(c => c.code === country);
          return {
            value: country,
            label: countryObj ? countryObj.name : country,
          };
        })}
        setValue={value => applyFilters({ ...selectedFilters, country: value })}
        value={selectedFilters.country}
        onClear={() => applyFilters({ ...selectedFilters, country: '' })}
      />
      <Menu
        title="Por Divisão Nacional"
        rounded
        allowEmpty
        placeholder={selectedFilters.country === '' ? 'Por Divisão Nacional' : selectedFilters.country}
        className={styles.menu}
        options={filters.countries?.map(country => {
          const countryObj = countriesData.find(c => c.code === country);
          return {
            value: country,
            label: countryObj ? countryObj.name : country,
          };
        })}
        setValue={value => applyFilters({ ...selectedFilters, country: value })}
        value={selectedFilters.country}
        onClear={() => applyFilters({ ...selectedFilters, country: '' })}
      />
    </div>
  );
}

ActionBar.propTypes = {
  // nameInputValue: PropTypes.string.isRequired,
  // nameInputChangeHandler: PropTypes.func.isRequired,
  // selectedFilters: PropTypes.object.isRequired
};
