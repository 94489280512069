import React from 'react';

const VerticalColorScale = ({ min = 0, max = 100, colors = ['#97C805', '#C80505'] }) => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px', justifyContent: 'center' }}
    >
      <span style={{ fontSize: '14px', color: '#333' }}>{max}</span>
      <div
        style={{
          width: '30px',
          height: '270px',
          border: '1px solid #aaa',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            background: `linear-gradient(to top, ${colors[0]}, ${colors[1]})`,
          }}
        />
      </div>
      <span style={{ fontSize: '14px', color: '#333' }}>{min}</span>
    </div>
  );
};

export default VerticalColorScale;
