/*eslint-disable eqeqeq*/
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import Preview from './Preview/Preview';
import Edition from './Edition/Edition';
import { useQuery } from 'shared/hooks';
import styles from './Preview/Preview.module.scss';
import { Spinner } from 'components/template';
import { getReport, createReport, updateMembership, deleteMembership } from 'store/ducks/reports';
import ShadowTeamReport from './ShadowTeamReport';
import { Creators as layoutActions } from 'store/ducks/layout';
import { useTranslation } from 'react-i18next';
import orderShadowTeamPositions from 'pages/Project/Workflow/ShadowTeam/ShadowTeamLineUp/helpers';
import ModalErroPermission from 'components/ModalErroPermission/ModalErroPermision';

const Reports = ({ match, setReport, report }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();
  const location = useLocation();

  const ids = query.get('ids') ? query.get('ids')?.split(',') : null;
  const projectId = query.get('project');
  const shadowTeamId = query.get('shadow_team');
  const shadowTeamPositions = query.get('positions');
  const type = location.state ? location.state.type : 'edition';

  const athleteReport = useSelector(state => state.athleteReport);
  const createdReport = useSelector(state => state.reports.report);

  const [reportData, setReportData] = useState({
    name: '',
    description: '',
    project: '',
    shadow_team: null,
    shadow_team_athletes: [],
    shadow_team_positions: [],
    shadow_team_positions_selected: [],
  });

  const [configuration, setConfiguration] = useState({
    generalFeatures: false,
    negotiation: false,
    opinion: false,
    background: false,
    images: false,
    evaluations: false,
    aspects: false,
    microaspects: false,
    historic: false,
    career: false,
    lastShadowTeamSchema: '',
    lastShadowTeamSchemaImage: '',
  });

  const [athletes, setAthletes] = useState();
  const [athletesInfoData, setAthletesInfoData] = useState([]);
  const initialAthleteData = {
    generalFeatures: '',
    negotiation: {},
    opinion: '',
    evaluationId: 0,
    microaspects: [],
    background: '',
    files: [],
    assigns: {},
  };

  useEffect(() => {
    if (ids) {
      const newReport = {
        ...reportData,
        configuration: {
          ...configuration,
        },
        athletes: ids.map(id => ({
          athlete: Number(id),
          reportData: {
            ...initialAthleteData,
          },
        })),
      };

      setReport(newReport);
      dispatch(createReport(newReport, projectId, shadowTeamId, shadowTeamPositions));
    } else {
      dispatch(getReport(match.params.id));
    }
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  // Created Report Setup
  useEffect(() => {
    if (createdReport.data) {
      if (ids) {
        window.location.href = `/relatorios/${createdReport.data?.id}`;
      }
      setConfiguration({
        generalFeatures: createdReport.data?.configuration.overall_aspects,
        negotiation: createdReport.data?.configuration.negotiation,
        opinion: createdReport.data?.configuration.opinion,
        background: createdReport.data?.configuration.background,
        images: createdReport.data?.configuration.images,
        evaluations: createdReport.data?.configuration.evaluations,
        aspects: createdReport.data?.configuration.aspects,
        microaspects: createdReport.data?.configuration.microaspects,
        historic: createdReport.data?.configuration.performance,
        career: createdReport.data?.configuration.career,
        lastShadowTeamSchema: createdReport.data?.configuration.lastShadowTeamSchema,
        lastShadowTeamSchemaImage: createdReport.data?.configuration.lastShadowTeamSchemaImage,
      });

      setReportData({
        name: createdReport.data?.name,
        description: createdReport.data?.description ? createdReport.data?.description : '',
        project: createdReport.data?.project?.name,
        shadow_team: createdReport.data?.shadow_team,
        shadow_team_athletes: createdReport.data?.shadow_team_athletes ?? [],
        shadow_team_positions: createdReport.data?.shadow_team_positions ?? [],
        shadow_team_positions_selected: createdReport.data?.shadow_team_positions_selected ?? [],
      });

      if (createdReport.data.athletes.length > 0) {
        const athleteNotEditableData = [];
        const athleteEditableData = [];

        createdReport.data.athletes.forEach(reportAthlete => {
          const { athlete, snapshot } = reportAthlete;

          athleteNotEditableData.push({
            id: athlete.id,
            athlete: athlete,
            historical: snapshot.performance ?? [],
            evaluations: snapshot.evaluations ?? [],
            transfers: snapshot.career?.transfers ?? [],
            contracts: snapshot.career?.contracts ?? [],
          });

          athleteEditableData.push({
            id: athlete.id,
            photo: athlete.photo,
            nickname: athlete.nickname,
            fullname: athlete.fullname,
            position: athlete.position,
            age: athlete.age,
            birthdate: athlete.birthdate,
            height: athlete.height,
            weight: athlete.weight,
            foot: athlete.foot,
            totalGoals: athlete.career_stats?.total_goals,
            totalMatches: athlete.career_stats?.total_matches,
            generalFeatures: reportAthlete?.report_data?.overall_aspects ?? '',
            negotiation: reportAthlete?.report_data?.negotiation ?? {},
            opinion: reportAthlete?.report_data?.opinion ?? '',
            background: reportAthlete?.report_data?.background ?? '',
            evaluationId: reportAthlete?.report_data?.evaluationId ?? 0,
            microaspects: reportAthlete?.report_data?.microaspects ?? [],
            files: reportAthlete?.report_data?.files ?? [],
            current_team: athlete?.current_team,
            nationalities: athlete?.nationalities,
            assigns: athlete?.assigns,
          });
        });

        setAthletes(athleteNotEditableData);
        setAthletesInfoData(athleteEditableData);
      } else {
        setAthletes([]);
        setAthletesInfoData([]);
      }
    }
  }, [createdReport.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let reportAthletes = athletesInfoData.map(athlete => ({
      athlete: Number(athlete.id),
      reportData: {
        generalFeatures: athlete.generalFeatures,
        negotiation: athlete.negotiation,
        opinion: athlete.opinion,
        evaluationId: athlete.evaluationId,
        microaspects: athlete.microaspects,
        background: athlete.background,
        files: athlete.files,
        assigns: athlete.assigns,
      },
    }));

    //se for um relatório de time-sombra
    if ((reportData.shadow_team_athletes?.length ?? 0) > 0) {
      //a ideia aqui é que a variável newReportAthletes contenha todos os atletas do time-sombra selecionados, ordenados devidamente por suas posições.
      const newReportAthletes = [];
      const nonSelectedAthletesIds = [];
      reportData.shadow_team_athletes.forEach(sta => {
        const athleteId = sta.athlete_data.id;
        if (nonSelectedAthletesIds.indexOf(athleteId) === -1) {
          nonSelectedAthletesIds.push(athleteId);
        }
      });
      orderShadowTeamPositions(reportData.shadow_team_positions).forEach(pos => {
        const positionAthletes = reportData.shadow_team_athletes.filter(sta => sta.position === pos.id);
        if (reportData.shadow_team_positions_selected.indexOf(pos.position_name) >= 0) {
          positionAthletes.forEach(shadowTeamAthlete => {
            const athleteId = shadowTeamAthlete.athlete_data.id;
            const selectAthleteIndex = nonSelectedAthletesIds.indexOf(athleteId);
            if (selectAthleteIndex !== -1) {
              nonSelectedAthletesIds.splice(selectAthleteIndex, 1);
              let reportAthleteJson = reportAthletes.find(a => a.athlete === athleteId);
              if (reportAthleteJson == null) {
                reportAthleteJson = {
                  athlete: athleteId,
                  reportData: {
                    ...initialAthleteData,
                  },
                };
              }
              newReportAthletes.push(reportAthleteJson);
            }
          });
        }
      });

      //caso tenha algum atleta de fora do time-sombra que foi adicionado manualmente no relatório, então ele será agregado aqui no final
      reportAthletes.forEach(reportAthlete => {
        const athleteId = reportAthlete.athlete;
        if (
          nonSelectedAthletesIds.indexOf(athleteId) === -1 &&
          !newReportAthletes.find(newReportAthlete => newReportAthlete.athlete === athleteId)
        ) {
          newReportAthletes.push(reportAthlete);
        }
      });

      if (newReportAthletes.length > 0) {
        reportAthletes = newReportAthletes;
      }
    }

    const updatedReport = {
      ...reportData,
      configuration: {
        ...configuration,
      },
      athletes: reportAthletes,
    };
    setReport(updatedReport);
  }, [configuration, reportData, athletesInfoData]); // eslint-disable-line react-hooks/exhaustive-deps

  const addAthlete = id => {
    const reportWithNewAthlete = {
      ...report,
      athletes: [
        ...report.athletes,
        {
          athlete: Number(id),
          reportData: {
            ...initialAthleteData,
          },
        },
      ],
    };

    setReport(reportWithNewAthlete);
  };

  const removeAthlete = async id => {
    dispatch(
      layoutActions.showToast({
        content: t('app.text3'),
        type: 'warning',
        duration: 3000,
      })
    );

    const deleted = await deleteMembership(match.params.id, id);
    if (deleted) {
      dispatch(getReport(match.params.id));
      dispatch(layoutActions.hideToast());
    } else {
      dispatch(
        layoutActions.showToast({
          content: t('rest.box19'),
          duration: 3000,
        })
      );
    }
  };

  const updateAthlete = async id => {
    dispatch(
      layoutActions.showToast({
        content: t('app.text3'),
        type: 'warning',
        duration: 3000,
      })
    );

    const updated = await updateMembership(match.params.id, id);
    if (updated) {
      dispatch(getReport(match.params.id));
    } else {
      dispatch(
        layoutActions.showToast({
          content: t('rest.box20'),
          duration: 3000,
        })
      );
    }
  };

  let content = '';
  const orderedAthletesId = report?.athletes?.map(a => a.athlete) ?? [];
  const isShadowTeam = reportData.shadow_team && (reportData.shadow_team_athletes?.length ?? 0) > 0;
  let loadingReport = athleteReport.loading || createdReport.loading || orderedAthletesId.length === 0;
  if (loadingReport && !createdReport.loading && createdReport.data?.athletes?.length === 0) {
    loadingReport = false;
  }
  if ((athleteReport.data == null && athleteReport.error) || (createdReport.data == null && createdReport.error)) {
    content = <ModalErroPermission isOpen={true} link="/projetos/relatorios" />;
  } else if (loadingReport) {
    content = (
      <div className={styles.loaderContainer}>
        <Spinner />
      </div>
    );
  } else if (isShadowTeam || (athletes?.length !== 0 && athletesInfoData?.length !== 0)) {
    content =
      type == 'edition' ? (
        <Edition
          addAthlete={addAthlete}
          removeAthlete={removeAthlete}
          updateAthlete={updateAthlete}
          setReportData={setReportData}
          reportData={reportData}
          athletes={athletes}
          orderedAthletesId={orderedAthletesId}
          configuration={configuration}
          setConfiguration={setConfiguration}
          setAthletesInfoData={setAthletesInfoData}
          athletesInfoData={athletesInfoData}
        />
      ) : (
        <>
          {isShadowTeam ? (
            <div className={[styles.container, 'printable'].join(' ')}>
              <ShadowTeamReport reportData={reportData} id="time-sombra-image" />
            </div>
          ) : (
            ''
          )}
          {orderedAthletesId.map(id => {
            const athleteEditableData = athletesInfoData.find(athlete => athlete.id == id);
            if (athleteEditableData) {
              return (
                <Preview
                  key={id}
                  id={id}
                  configuration={configuration}
                  athleteEditableData={athleteEditableData}
                  athlete={athletes.find(athlete => athlete.id == id)}
                  reportData={reportData}
                />
              );
            }
            return '';
          })}
        </>
      );
  }

  return <>{content}</>;
};

export default withRouter(Reports);
