import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Filter from './Filter/Filter';
import AthleteList from './AthleteList/AthleteList';
import { usePagination } from 'shared/hooks';
import { getDesiredAthletes } from 'store/ducks/transferAthletes';
import styles from './DesiredAthletes.module.scss';
import { Pagination, Spinner } from 'components/template';
import { useTranslation } from 'react-i18next';
import ActiveFilters from './ActiveFilters/ActiveFilters';
import { getCountries } from 'store/ducks/countries';

function DesiredAthletes() {
  const dispatch = useDispatch();
  const desiredTransferMarket = useSelector(state => state.transferAthletes);
  const countriesSelector = useSelector(state => state.countries.countries);
  const countries = countriesSelector.data ?? [];
  const user = useSelector(state => state.user);
  const { t } = useTranslation();

  const [pagination, setPagination, defaultPagination] = usePagination();
  const [queryFilters, setQueryFilters] = useState(null);
  const [activeFilters, setActiveFilters] = useState({
    gender: user.data.permission_gender === 'female' ? 'female' : 'male',
    isApplyButton: false,
  });

  useEffect(() => {
    dispatch(getDesiredAthletes(activeFilters));
  }, []);

  const load_athletes = async filters => {
    dispatch(getDesiredAthletes(filters));
  };

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    if (user.data.plan.mercado_de_transferencias) {
      setPagination(defaultPagination);
    }
    load_athletes({ ...queryFilters, ...defaultPagination });
  }, [dispatch, queryFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSetQueryFilters = filters => {
    let emptyObj;

    for (let filter in filters) {
      if (Array.isArray(filters[filter]) && filters[filter].length === 0) {
        delete filters[filter];
      } else if (typeof filters[filter] === 'object') {
        emptyObj = true;

        for (let key in filters[filter]) {
          if (filters[filter][key] !== '') {
            emptyObj = false;
          }
        }

        if (emptyObj) {
          delete filters[filter];
        }
      } else if (filters[filter] === '') {
        delete filters[filter];
      } else if (filter === 'watched' && !filters[filter]) {
        delete filters[filter];
      }
    }
    setActiveFilters(filters);
  };

  const handlePagination = newPagination => {
    setPagination(newPagination);
    load_athletes({ ...queryFilters, ...newPagination });
  };

  return (
    <div className={styles.container}>
      <>
        <div className={styles.content}>
          <Filter
            desiredTransferMarket={desiredTransferMarket}
            countries={countries}
            setQueryFilters={setQueryFilters}
            onSetQueryFilters={onSetQueryFilters}
            activeFilters={activeFilters}
            className={styles.filter}
            autoSearch={true}
          />
          <div className={styles.tableContainer}>
            <ActiveFilters setActiveFilters={setActiveFilters} activeFilters={activeFilters} countries={countries} />
            {desiredTransferMarket.loading && (
              <div className={styles.overlay}>
                <div className={styles.loader}>
                  <Spinner />
                </div>
              </div>
            )}
            <AthleteList
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              countries={countries}
              athletes={desiredTransferMarket}
              user={user}
              queryFilters={queryFilters}
            />
            <Pagination
              length={desiredTransferMarket.data.length}
              left={315}
              hasNext={desiredTransferMarket.hasNext}
              hasPrevious={desiredTransferMarket.hasPrevious}
              pagination={pagination}
              count={desiredTransferMarket.count}
              setPagination={handlePagination}
              resourceDisplayName={t('filters.ath')}
            />
          </div>
        </div>
      </>
    </div>
  );
}

export default withRouter(DesiredAthletes);
