/* eslint-disable react/prop-types */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Home from 'pages/Home/Home';
import Configuration from 'pages/Configuration';
import Team from 'pages/Club/Club';
import NationalTeams from 'pages/NationalClubs/NationalClubs';
import Athlete from 'pages/Athlete/Athlete';
import AthleteEvaluation from 'pages/Athlete/Evaluation/Evaluation';
import AdvancedSearch from 'pages/AdvancedSearch/AdvancedSearch';
import ForeignClubs from 'pages/ForeignClubs/ForeignClubs';
import AthleteReports from 'pages/Athlete/Reports/Reports';
import YouthLeagueCompetition from 'pages/Competition/YouthLeague/YouthLeague';
import YouthLeagueCompetitionClubs from 'pages/Competition/YouthLeague/Clubs/Clubs';
import Agencies from 'pages/Agencies';
import AgencyAthletes from 'pages/AgencyAthletes';
import Profile from 'pages/Profile';
import Project from 'pages/Project';
import NewProject from 'pages/NewProject';
import ProjectWorkflow from 'pages/Project/Workflow';
import ShadowTeamLineUp from 'pages/Project/Workflow/ShadowTeam/ShadowTeamLineUp';
import ReportLayout from './ReportLayout/ReportLayout';
import Layout from './Layout/Layout';
import TransferMarket from 'pages/TransferMarket/TransferMarket';
import MyClub from 'pages/MyClub/MyClub';
import MyAgency from 'pages/MyAgency/MyAgency';
import MoreInfo from 'pages/TransferMarket/AvailableAthletes/MoreInfo/MoreInfo';
import DesiredAthletesMoreInfo from 'pages/TransferMarket/DesiredAthletes/MoreInfo/MoreInfo';
import ReportsPRO from 'pages/Athlete/ReportsPRO/ReportsPRO';
import Messages from 'pages/Messages/Messages';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import FootlinkClubs from 'pages/FootlinkClubs/FootlinkClubs';
import FootlinkOriginals from 'pages/FootlinkOriginals/FootlinkOriginals';
import VideoPlayer from 'pages/FootlinkOriginals/VideoPlayer/VideoPlayer';
import Dashboard from 'pages/Dashboard/Dashboard';
import TransferRadar from 'pages/TransferRadar/TransferRadar';

export default function Routes() {
  const user = useSelector(state => state.user);

  let routes = user?.data?.is_super_user
    ? [
        <Route
          key="/dashboard"
          path="/dashboard"
          exact
          render={() => (
            <Layout>
              <Dashboard />
            </Layout>
          )}
        />,
      ]
    : [];

  routes.push(...routes, [
    <Route path="/recuperacao-senha/:uid/:token" exact component={ResetPassword} />,
    <Route
      key="/configuracoes"
      path="/configuracoes"
      exact
      render={() => (
        <Layout>
          <Configuration />
        </Layout>
      )}
    />,
    <Route
      key="/configuracoes/informacoes_sistema"
      path="/configuracoes/informacoes_sistema"
      exact
      render={() => (
        <Layout>
          <Configuration />
        </Layout>
      )}
    />,
    <Route
      key="/perfil"
      path="/perfil"
      render={() => (
        <Layout>
          <Profile />
        </Layout>
      )}
    />,
    <Route
      key="/clube/:id"
      path="/clube/:id"
      render={() => (
        <Layout>
          <Team />
        </Layout>
      )}
    />,
    <Route
      key="/base/competicoes/:id"
      path="/base/competicoes/:id"
      render={() => (
        <Layout>
          <YouthLeagueCompetitionClubs />
        </Layout>
      )}
    />,
    <Route
      key="/relatorios/criar"
      path="/relatorios/criar"
      render={() => (
        <ReportLayout>
          <AthleteReports />
        </ReportLayout>
      )}
    />,
    <Route
      key="/projetos/:id/workflow"
      path="/projetos/:id/workflow"
      render={() => (
        <Layout>
          <ProjectWorkflow />
        </Layout>
      )}
    />,
    <Route
      key="/projetos/:project_id/time-sombra/:id"
      path="/projetos/:project_id/time-sombra/:id"
      render={() => (
        <Layout isAppBarVisible={false}>
          <ShadowTeamLineUp />
        </Layout>
      )}
    />,
    <Route
      key="/projetos/novo"
      path="/projetos/novo"
      exact
      render={() => (
        <Layout>
          <NewProject />
        </Layout>
      )}
    />,
    <Route
      key="/projetos"
      path="/projetos"
      render={() => (
        <Layout>
          <Project />
        </Layout>
      )}
    />,
    <Route
      key="/mensagens/:repository?/:id?"
      path="/mensagens/:repository?/:id?"
      exact
      render={() => (
        <Layout>
          <Messages />
        </Layout>
      )}
    />,
  ]);

  routes = user.data.is_only_for_projects
    ? routes
    : [
        ...routes,
        <Route
          key="/home"
          path="/home"
          exact
          render={() => (
            <Layout>
              <Home />
            </Layout>
          )}
        />,
        <Route
          key="/originals"
          path="/originals"
          exact
          render={() => (
            <Layout darkMode={true}>
              <FootlinkOriginals />
            </Layout>
          )}
        />,
        <Route key="/originals/play/:id" path="/originals/play/:id" exact render={() => <VideoPlayer />} />,
        <Route
          key="/clubes-brasileiros"
          path="/clubes-brasileiros"
          render={() => (
            <Layout>
              <NationalTeams />
            </Layout>
          )}
        />,
        <Route
          key="/clubes-estrangeiros/:countryCode"
          path="/clubes-estrangeiros/:countryCode"
          render={() => (
            <Layout>
              <ForeignClubs />
            </Layout>
          )}
        />,
        <Route
          key="/clubes-estrangeiros/"
          path="/clubes-estrangeiros/"
          render={() => (
            <Layout>
              <ForeignClubs />
            </Layout>
          )}
        />,
        <Route
          key="/clubes-footlink/"
          path="/clubes-footlink/"
          render={() => (
            <Layout>
              <FootlinkClubs />
            </Layout>
          )}
        />,
        <Route
          key="/busca-avancada"
          path="/busca-avancada"
          render={() => (
            <Layout>
              <AdvancedSearch />
            </Layout>
          )}
        />,
        <Route
          key="/base/competicoes"
          path="/base/competicoes"
          render={() => (
            <Layout>
              <YouthLeagueCompetition />
            </Layout>
          )}
        />,
        <Route
          key="/agencias/:id/atletas"
          path="/agencias/:id/atletas"
          render={() => (
            <Layout>
              <AgencyAthletes />
            </Layout>
          )}
        />,
        <Route
          key="/agencias"
          path="/agencias"
          render={() => (
            <Layout>
              <Agencies />
            </Layout>
          )}
        />,
      ];
  if (user.data.plan?.meu_clube) {
    routes.push(
      <Route
        key="/meu-clube"
        path="/meu-clube"
        render={() => (
          <Layout>
            <MyClub />
          </Layout>
        )}
      ></Route>
    );
  }
  if (user.data.plan?.mercado_de_transferencias) {
    routes.push(
      <Route
        key="/transfer-market"
        path="/transfer-market"
        render={() => (
          <Layout>
            <TransferMarket />
          </Layout>
        )}
      />,
      <Route
        key="/more-info/:id"
        path="/more-info/:id"
        render={() => (
          <Layout>
            <MoreInfo />
          </Layout>
        )}
      />,
      <Route
        key="/desired-athletes/:id"
        path="/desired-athletes/:id"
        render={() => (
          <Layout>
            <DesiredAthletesMoreInfo />
          </Layout>
        )}
      />
    );
  }
  if (user.data.plan?.evaluations_limit !== 0) {
    routes.push(
      <Route
        key="/atletas/:athleteId/avaliacoes/nova"
        path="/atletas/:athleteId/avaliacoes/nova"
        exact
        render={props => (
          <Layout>
            <AthleteEvaluation key={props.match.params.evaluationId || 1} {...props} isNew />
          </Layout>
        )}
      />,
      <Route
        key="/atletas/:athleteId/avaliacoes/:evaluationId(\d+)"
        path="/atletas/:athleteId/avaliacoes/:evaluationId(\d+)"
        render={props => (
          <Layout>
            <AthleteEvaluation {...props} />
          </Layout>
        )}
      />
    );
  }
  routes.push(
    <Route
      key="/atletas/:id"
      path="/atletas/:id"
      render={() => (
        <Layout>
          <Athlete />
        </Layout>
      )}
    />
  );
  routes.push(
    <Route
      key="/atletas/:athleteId/reportsPRO"
      path="/atletas/:athleteId/reportsPRO"
      exact
      render={props => (
        <Layout>
          <ReportsPRO />
        </Layout>
      )}
    />
  );
  // routes.push(
  //   <Route
  //     key="/transfer-radar"
  //     path="/transfer-radar"
  //     render={() => (
  //       <Layout>
  //         <TransferRadar />
  //       </Layout>
  //     )}
  //   ></Route>
  // );
  if (user?.data?.organization?.id?.type === 'agency') {
    routes.push(
      <Route
        key="/minha-agencia"
        path="/minha-agencia"
        render={() => (
          <Layout>
            <MyAgency />
          </Layout>
        )}
      ></Route>
    );
  }

  return (
    <Switch>
      {routes}
      {user.data.is_only_for_projects ? <Redirect to="/projetos" /> : <Redirect to="/home" />}
    </Switch>
  );
}
