import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectionButton } from 'components/template';
import styles from './ActionBar.module.scss';
import { useTranslation } from 'react-i18next';

export default function ActionBar({ selectedFilters, setSelectedFilters }) {
  const { t } = useTranslation();
  const [maleCastSelected, setMaleCastSelected] = useState('');
  const [femaleCastSelected, setFemaleCastSelected] = useState(null);
  const user = useSelector(state => state.user.data);

  return (
    <div className={styles.buttonsTop}>
      {user.permission_gender === 'male' && (
        <div className={styles.divMale}>
          <span className={styles.spanMale}>{t('listTranslation.Male')}: </span>
          <SelectionButton
            items={[
              { label: t('compe.text26'), value: 'professional' },
              { label: t('text.box31'), value: 'loaned' },
              { label: t('text.box32'), value: 'amateur' },
            ]}
            selected={maleCastSelected}
            onClick={val => {
              const loaned = val === 'loaned';
              setSelectedFilters({
                ...selectedFilters,
                female: false,
                professional: loaned ? null : val === 'professional',
                loaned,
              });
              setMaleCastSelected(val);
              setFemaleCastSelected(null);
            }}
          />
        </div>
      )}
      {user.permission_gender === 'female' && (
        <div className={styles.divFemale}>
          <span className={styles.spanFemale}>{t('listTranslation.Female')}: </span>
          <SelectionButton
            items={[
              { label: t('compe.text26'), value: 'professional' },
              { label: t('text.box38'), value: 'loaned' },
              { label: t('text.box37'), value: 'amateur' },
            ]}
            selected={femaleCastSelected}
            onClick={val => {
              const loaned = val === 'loaned';
              setSelectedFilters({
                ...selectedFilters,
                female: true,
                professional: loaned ? null : val === 'professional',
                loaned,
              });
              setMaleCastSelected(null);
              setFemaleCastSelected(val);
            }}
          />
        </div>
      )}
      {user.permission_gender === 'general' && (
        <>
          <div className={styles.divMale}>
            <span className={styles.spanMale}>{t('listTranslation.Male')}: </span>
            <SelectionButton
              items={[
                { label: t('compe.text26'), value: 'professional' },
                { label: t('text.box31'), value: 'loaned' },
                { label: t('text.box32'), value: 'amateur' },
              ]}
              selected={maleCastSelected}
              onClick={val => {
                const loaned = val === 'loaned';
                setSelectedFilters({
                  ...selectedFilters,
                  female: false,
                  professional: loaned ? null : val === 'professional',
                  loaned,
                });
                setMaleCastSelected(val);
                setFemaleCastSelected(null);
              }}
            />
          </div>
          <div className={styles.divFemale}>
            <span className={styles.spanFemale}>{t('listTranslation.Female')}: </span>
            <SelectionButton
              items={[
                { label: t('compe.text26'), value: 'professional' },
                { label: t('text.box38'), value: 'loaned' },
                { label: t('text.box37'), value: 'amateur' },
              ]}
              selected={femaleCastSelected}
              onClick={val => {
                const loaned = val === 'loaned';
                setSelectedFilters({
                  ...selectedFilters,
                  female: true,
                  professional: loaned ? null : val === 'professional',
                  loaned,
                });
                setMaleCastSelected(null);
                setFemaleCastSelected(val);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}
