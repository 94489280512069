import React, { useState } from 'react';
import { FaClipboardList } from 'react-icons/fa';
import { AiOutlineSearch } from 'react-icons/ai';
import { Input, Menu, SliderMenu, Button, Checkbox } from 'components/template';
import { useTranslation } from 'react-i18next';
import styles from './ActionBar.module.scss';
import { positionOptions } from 'shared/mocks';
import { useSelector } from 'react-redux';

export default function ActionBar({ checkedAthletes, selectedFilters, setSelectedFilters, list }) {
  const [timeoutId, setTimeoutId] = useState(null);
  const [name, setName] = useState(selectedFilters.name);
  const { t } = useTranslation();
  const user = useSelector(state => state.user.data);

  const handleInputChange = value => {
    clearTimeout(timeoutId);
    setName(value);
    setTimeoutId(
      setTimeout(() => {
        setSelectedFilters({ ...selectedFilters, name: value });
      }, 800)
    );
  };

  return (
    <div className={styles.actionBar}>
      <div className={styles.actionButtons}>
        <Button
          className={styles.iconButton}
          onClick={() => window.open(`/relatorios/criar?ids=${checkedAthletes.map(athlete => athlete.id).join(',')}`)}
          color="vividGreen"
          disabled={checkedAthletes.length === 0}
        >
          <div className={styles.text}>
            {t('athlete_list.tip')}
            <FaClipboardList className={styles.icon} />
          </div>
        </Button>
      </div>
      <Input
        rounded
        placeholder={t('agencies.name')}
        containerClassName={styles.inputContainer}
        onChange={e => {
          handleInputChange(e.target.value);
        }}
        icon={<AiOutlineSearch className={styles.icon} />}
        iconPosition="right"
        value={name}
      />
      <Menu
        title={t('agencies.lists')}
        largeMenu
        rounded
        allowEmpty
        placeholder={t('agencies.lists')}
        className={styles.menu}
        options={list ?? []}
        value={selectedFilters.lists}
        onClear={() => {
          setSelectedFilters({ ...selectedFilters, lists: [] });
        }}
        setValue={val => {
          setSelectedFilters({ ...selectedFilters, lists: val });
        }}
      />
      <SliderMenu
        rounded
        title={t('athlete_card.birth')}
        className={styles.menu1}
        setFilter={val => {
          const [min, max] = val;
          setSelectedFilters({ ...selectedFilters, min_birthyear: min, max_birthyear: max });
        }}
        onClear
      />
      <Menu
        title={t('athlete_card.pos')}
        largeMenu
        multiple
        rounded
        allowEmpty
        placeholder={t('athlete_card.pos')}
        fixedPlaceholder
        className={styles.menu}
        options={positionOptions.map(item => ({ label: t(`positionOptions.${item.value}`), value: item.label }))}
        value={selectedFilters.position}
        onClear={() => {
          setSelectedFilters({ ...selectedFilters, position: [] });
        }}
        setValue={val => {
          setSelectedFilters({ ...selectedFilters, position: val });
        }}
      />
      {user.permission_gender === 'general' && (
        <div style={{ marginLeft: '10px' }}>
          <Checkbox
            value={selectedFilters.gender || ''}
            onChange={e =>
              setSelectedFilters(prevFilters => ({
                ...prevFilters,
                gender: prevFilters.gender === 'female' ? '' : 'female',
              }))
            }
            label={'Somente atletas femininas'}
            checked={selectedFilters.gender === 'female'}
            dark
          />
        </div>
      )}
      <div className={styles.counterContainer}>
        <div className={styles.text}></div>
      </div>
    </div>
  );
}
