import React from 'react';
import styles from './Ranking.module.scss';
import BrasilImg from 'assets/escudos/brasil.png';

const data = [
  { country: 'Brasil', athletes: 250 },
  { country: 'Estados Unidos', athletes: 340 },
  { country: 'França', athletes: 180 },
  { country: 'China', athletes: 400 },
  { country: 'Alemanha', athletes: 150 },
  { country: 'Índia', athletes: 220 },
];

const topCountries = data.sort((a, b) => b.athletes - a.athletes).slice(0, 5);

const Ranking = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.title}>Top 5 paises</span>
        <span className={styles.subTitle}>
          Países com mais <br /> atletas monitorados
        </span>
      </div>
      <div className={styles.list}>
        {topCountries.map((item, index) => (
          <div key={index} className={styles.item}>
            <span className={styles.rank}>{index + 1}.</span>
            <img className={styles.rankImg} src={BrasilImg}></img>
            <span className={styles.athletes}>{item.athletes}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ranking;
