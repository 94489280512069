/*eslint-disable eqeqeq*/
import React, { useState, useEffect, useRef } from 'react';
import CustomCollapse from './CustomCollapse/CustomCollapse';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash/lang';
import styles from './Filter.module.scss';
import { InputApply, Card, Spinner, Menu, Input } from 'components/template';
import CheckboxContainerLists from './CheckboxContainerLists/CheckboxContainerLists';
import { getCountries } from 'store/ducks/countries';
import { useLocation } from 'react-router-dom';
import {
  positionOptions,
  checkboxesAdvancedSearch,
  radioAdvancedSearch,
  brazilianStates,
} from '../../../../shared/mocks';
import CheckboxContainer from './CheckboxContainer/CheckboxContainer';
import RadioContainer from './RadioContainer/RadioContainer';
import { MinMaxDate, MinMaxSeason } from 'pages/AdvancedSearch/Filter/CustomInputs/CustomInputs';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import { Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { moneyStringToNumber, formatMoneyString, debounce } from 'shared/utility';

export default function Filter({
  athletes,
  setQueryFilters,
  onSetQueryFilters,
  activeFilters,
  overlay,
  autoSearch,
  className,
}) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const location = useLocation();
  const user = useSelector(state => state.user.data);

  const countries = useSelector(state => state.countries.countries);

  const [loadingState, setLoadingState] = useState(true);

  const initialFilters = {
    position: [],
    nationalities: [],
    foot: '',
    gender: '',
    negotiation_type: [],
    seasonMatches: {
      season: '',
      min: '',
      max: '',
    },
    seasonGoals: {
      season: '',
      min: '',
      max: '',
    },
    cbfCode: '',
    birthyear: {
      min: '',
      max: '',
    },
    sale: {
      min: '',
      max: '',
    },
    salary: {
      min: '',
      max: '',
    },
    division: [],
    country: '',
    state: '',
    watched: false,
    lists: '',
  };

  const mounted = useRef(false);

  const [filters, setFilters] = useState(location?.state?.queryFilters || initialFilters);

  useEffect(() => {
    const filtersFromActive = { ...initialFilters, ...activeFilters };

    if (!isEqual(filtersFromActive, filters)) {
      setFilters(filtersFromActive);
    }
  }, [activeFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoadingState(countries.loading);
  }, [countries.loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (countries.data.length === 0 && !countries.loading) {
      dispatch(getCountries());
    }
  }, [dispatch, getCountries, countries.data.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const now = new Date();
    if (!mounted.current) {
      mounted.current = now;

      let parentQueryFilters = location?.state?.queryFilters;
      if (parentQueryFilters == null) {
        parentQueryFilters = getFiltersStorage();
      }
      if (parentQueryFilters) {
        onSetFilters({ ...initialFilters, ...parentQueryFilters });
      }
    } else if (now.getTime() - mounted.current.getTime() > 500) {
      if (isEqual(filters, initialFilters)) {
        removeFiltersStorage();
      } else {
        saveFiltersStorage(filters);
      }

      if (autoSearch || (filters.isApplyButton ?? false)) {
        setQueryFilters(queryFiltersParser(filters));
      }
    }
  }, [setQueryFilters, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFiltersStorage = () => {
    return JSON.parse(sessionStorage.getItem('transfer_market_search'));
  };

  const saveFiltersStorage = filters => {
    sessionStorage.setItem('transfer_market_search', JSON.stringify(filters));
  };

  const removeFiltersStorage = () => {
    sessionStorage.removeItem('transfer_market_search');
  };

  const onSetFilters = newFilters => {
    const isApplyButton = newFilters.isApplyButton ?? false;
    if (isApplyButton || !isEqual(newFilters, filters)) {
      setFilters(newFilters);
      if (autoSearch || isApplyButton) {
        onSetQueryFilters({ ...newFilters });
      }
    }
  };

  const negotiationFilterParser = arr => {
    if (arr.length === 1) {
      if (arr[0] === 'SALE') {
        return 'SALE';
      } else if (arr[0] === 'LOAN') {
        return 'LOAN';
      }
    }
    return null;
  };

  const queryFiltersParser = filters => {
    const params = {
      transfermarket: filters.transfer_market,
      position: filters.position,
      nationalities: filters.nationalities,
      foot: filters.foot,
      female: user.permission_gender !== 'female' ? filters.gender === 'female' : true,
      season_matches: filters.seasonMatches.season,
      min_matches_in_season: filters.seasonMatches.min,
      max_matches_in_season: filters.seasonMatches.max,
      season_goals: filters.seasonGoals.season,
      min_goals_in_season: filters.seasonGoals.min,
      max_goals_in_season: filters.seasonGoals.max,
      min_birthyear: filters.birthyear.min,
      max_birthyear: filters.birthyear.max,
      min_annual_salary: filters.salary.min,
      max_annual_salary: filters.salary.max,
      min_sale_value: filters.sale.min,
      max_sale_value: filters.sale.max,
      negotiation_type: negotiationFilterParser(filters.negotiation_type.map(item => item)),
      cbf_code: filters.cbfCode,
      division: filters.division,
      team_country: filters.country,
      team_state: filters.state,
      watched: filters.watched,
      lists: filters.lists,
    };

    return params;
  };

  const useStyles = makeStyles({
    root: {
      color: '#5A00FF',
      height: 35,
      '& .MuiSlider-rail': {
        height: 3,
      },
      '& .MuiSlider-track': {
        height: 3,
      },
      '& .MuiSlider-thumb': {
        color: '#ffffff',
        width: 20,
        height: 20,
        marginTop: -9,
        border: '4px solid #5A00FF',
      },
    },
  });

  const saleValueNullCheck = athletes?.max_sale_value;
  const salaryValueNullCheck = athletes.max_salary_value;

  const classes = useStyles();
  const [sliderSaleValue, setSliderSaleValue] = useState([0, saleValueNullCheck]);
  const [sliderSalaryValue, setSliderSalaryValue] = useState([0, salaryValueNullCheck]);

  const [inputSaleValueMin, setInputSaleValueMin] = useState('0');
  const [inputSaleValueMax, setInputSaleValueMax] = useState('0');
  const [inputSalaryValueMin, setInputSalaryValueMin] = useState('0');
  const [inputSalaryValueMax, setInputSalaryValueMax] = useState('0');

  useEffect(() => {
    setSliderSaleValue([0, saleValueNullCheck]);
    setSliderSalaryValue([0, salaryValueNullCheck]);
    if (saleValueNullCheck) {
      setInputSaleValueMax(saleValueNullCheck.toString());
      setInputSalaryValueMax(salaryValueNullCheck.toString());
    }
  }, [saleValueNullCheck, salaryValueNullCheck]);

  const onSliderSellChange = (event, newValues) => {
    setSliderSaleValue(newValues);
    debounce(onSetFilters)({ ...filters, sale: { min: newValues[0], max: newValues[1] } });
  };

  function onSliderSalaryChange(event, newValues) {
    setSliderSalaryValue(newValues);
    debounce(onSetFilters)({ ...filters, salary: { min: newValues[0], max: newValues[1] } });
  }

  let content;
  content = (
    <div>
      {overlay && <div className={styles.overlay} />}
      <Card borderedGrey className={className}>
        <Scrollbars
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: '10px',
                height: '165px !important',
                borderRadius: '5px',
                backgroundColor: '#D7D6D2',
                right: '5px',
              }}
            />
          )}
          renderThumbHorizontal={({ style, ...props }) => (
            <div {...props} style={{ ...style, borderRadius: '5px', backgroundColor: '#D7D6D2' }} />
          )}
        >
          {loadingState ? (
            <div className={styles.loader}>
              <Spinner />
            </div>
          ) : (
            <>
              <CustomCollapse openedDefault title={t('search.typeNegotiation')}>
                <CheckboxContainer
                  setFilter={value => {
                    onSetFilters({ ...filters, negotiation_type: value, isApplyButton: false });
                  }}
                  value={filters.negotiation_type || null}
                  checkboxes={checkboxesAdvancedSearch.negotiation_type.map(item => ({
                    label: t(`negotiationOptions.${item.value}`),
                    value: item.value,
                  }))}
                />
              </CustomCollapse>
              {user.permission_gender === 'general' && (
                <CustomCollapse openedDefault title={t('filters.gender')}>
                  <RadioContainer
                    setFilter={value => onSetFilters({ ...filters, gender: value, isApplyButton: false })}
                    value={
                      filters.gender ||
                      (user.permission_gender === 'male' || user.permission_gender === 'general' ? 'male' : 'female')
                    }
                    options={radioAdvancedSearch.gender.map(item => ({
                      label: t(`radioAdvancedSearch.${item.value}`),
                      value: item.value,
                    }))}
                    name={t('filters.gen')}
                  />
                </CustomCollapse>
              )}

              <CheckboxContainerLists
                setFilter={(listsIds, watched) =>
                  onSetFilters({ ...filters, lists: listsIds, watched: watched, isApplyButton: true })
                }
                listsIds={filters.lists || ''}
                watched={filters.watched || false}
              />
              <CustomCollapse openedDefault title={t('search.valueForSale')}>
                <div className={styles.slideBar}>
                  <div className={styles.spanValue}>
                    <span>
                      €{formatMoneyString(inputSaleValueMin)}- €{formatMoneyString(inputSaleValueMax)}
                    </span>
                  </div>
                  <div className={classes.root}>
                    <Slider
                      value={sliderSaleValue}
                      onChange={(event, newValues) => {
                        onSliderSellChange(event, newValues);
                        setInputSaleValueMin(newValues[0].toString());
                        setInputSaleValueMax(newValues[1].toString());
                      }}
                      valueLabelDisplay="off"
                      min={0}
                      max={athletes?.max_sale_value}
                      className={classes.slider}
                    />
                  </div>
                  <div
                    className={styles.sliderInput}
                    style={{ display: 'flex', height: '50px', justifyContent: 'space-between' }}
                  >
                    <Input
                      inputClassName={styles.sellMin}
                      value={inputSaleValueMin}
                      prefix="€ "
                      type="currency"
                      onValueChange={(value, name) => {
                        setInputSaleValueMin(value);
                        onSliderSellChange(null, [moneyStringToNumber(value), sliderSaleValue[1]]);
                      }}
                    />
                    <Input
                      inputClassName={styles.sellMax}
                      value={inputSaleValueMax}
                      prefix="€ "
                      type="currency"
                      onValueChange={(value, name) => {
                        setInputSaleValueMax(value);
                        onSliderSellChange(null, [sliderSaleValue[0], moneyStringToNumber(value)]);
                      }}
                    />
                  </div>
                </div>
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('slider_menu.value_salary')}>
                <div className={styles.slideBar}>
                  <div className={styles.spanValue}>
                    <span>
                      €{formatMoneyString(inputSalaryValueMin)}- €{formatMoneyString(inputSalaryValueMax)}
                    </span>
                  </div>
                  <div className={classes.root}>
                    <Slider
                      value={sliderSalaryValue}
                      onChange={(event, newValues) => {
                        onSliderSalaryChange(event, newValues);
                        setInputSalaryValueMin(newValues[0].toString());
                        setInputSalaryValueMax(newValues[1].toString());
                      }}
                      valueLabelDisplay="off"
                      min={0}
                      max={athletes?.max_salary_value}
                      className={classes.slider}
                    />
                  </div>
                  <div
                    className={styles.sliderInput}
                    style={{ display: 'flex', height: '50px', justifyContent: 'space-between' }}
                  >
                    <Input
                      inputClassName={styles.sellMin}
                      value={inputSalaryValueMin}
                      prefix="€ "
                      type="currency"
                      onValueChange={(value, name) => {
                        setInputSalaryValueMin(value);
                        onSliderSalaryChange(null, [moneyStringToNumber(value), sliderSalaryValue[1]]);
                      }}
                    />
                    <Input
                      inputClassName={styles.sellMax}
                      value={inputSalaryValueMax}
                      prefix="€ "
                      type="currency"
                      onValueChange={(value, name) => {
                        setInputSalaryValueMax(value);
                        onSliderSalaryChange(null, [sliderSalaryValue[0], moneyStringToNumber(value)]);
                      }}
                    />
                  </div>
                </div>
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.pos')}>
                <CheckboxContainer
                  setFilter={value => onSetFilters({ ...filters, position: value, isApplyButton: false })}
                  value={filters.position || null}
                  checkboxes={positionOptions.map(item => ({
                    label: t(`positionOptions.${item.value}`),
                    value: item.label,
                  }))}
                />
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.nation')}>
                <CheckboxContainer
                  setFilter={value => onSetFilters({ ...filters, nationalities: value, isApplyButton: false })}
                  value={filters.nationalities || null}
                  checkboxes={countries.data.map(country => ({ label: country.name, value: country.code }))}
                />
              </CustomCollapse>
              <CustomCollapse customStyle={styles.collapseWithMessage} openedDefault title={t('filters.games')}>
                <div className={styles.menuMinMaxContainer}>
                  <MinMaxSeason
                    setFilter={value => onSetFilters({ ...filters, seasonMatches: value, isApplyButton: false })}
                    onClear={() =>
                      onSetFilters({
                        ...filters,
                        seasonMatches: {
                          season: '',
                          min: '',
                          max: '',
                        },
                      })
                    }
                    value={filters.seasonMatches}
                  />
                </div>
              </CustomCollapse>
              <CustomCollapse customStyle={styles.collapseWithMessage} openedDefault title={t('filters.gols')}>
                <div className={styles.menuMinMaxContainer}>
                  <MinMaxSeason
                    setFilter={value => onSetFilters({ ...filters, seasonGoals: value, isApplyButton: false })}
                    onClear={() =>
                      onSetFilters({
                        ...filters,
                        seasonGoals: {
                          season: '',
                          min: '',
                          max: '',
                        },
                      })
                    }
                    value={filters.seasonGoals}
                  />
                </div>
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.cbf_num')}>
                <div className={styles.cbfInput}>
                  <InputApply
                    value={filters.cbfCode}
                    onClick={value => onSetFilters({ ...filters, cbfCode: value, isApplyButton: false })}
                  />
                </div>
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.birt_year')}>
                {/* <div className={styles.MinMaxDate}> */}
                <div className={styles.minMaxDateChildren}>
                  <MinMaxDate
                    from
                    title={t('slider_menu.in')}
                    titlePlaceholder={
                      (filters?.birthyear?.min ?? '').toString().length > 0 ? filters.birthyear.min : '---'
                    }
                    setFilter={values =>
                      onSetFilters({
                        ...filters,
                        birthyear: {
                          ...filters.birthyear,
                          min: values.min,
                        },
                        isApplyButton: false,
                      })
                    }
                    onClear={() => onSetFilters({ ...filters, birthyear: { ...filters.birthyear, min: '' } })}
                    valueMin={filters.birthyear.min}
                    valueMax={filters.birthyear.max}
                  />
                </div>
                <div className={styles.minMaxDateChildren}>
                  <MinMaxDate
                    to
                    title={t('slider_menu.e')}
                    titlePlaceholder={
                      (filters?.birthyear?.max ?? '').toString().length > 0 ? filters.birthyear.max : '---'
                    }
                    setFilter={values =>
                      onSetFilters({
                        ...filters,
                        birthyear: {
                          ...filters.birthyear,
                          max: values.max,
                        },
                        isApplyButton: false,
                      })
                    }
                    onClear={() => onSetFilters({ ...filters, birthyear: { ...filters.birthyear, max: '' } })}
                    valueMin={filters.birthyear.min}
                    valueMax={filters.birthyear.max}
                  />
                </div>
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.division_ope')}>
                <CheckboxContainer
                  setFilter={value => onSetFilters({ ...filters, division: value, isApplyButton: false })}
                  value={filters.division || null}
                  checkboxes={checkboxesAdvancedSearch.newDivision.map(item => ({
                    label: t(`newDivisionOptions.${item.id}`),
                    value: item.value,
                  }))}
                />
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.country_ope')}>
                <Menu
                  className={styles.largeSelect}
                  title={t('filters.country_ope')}
                  placeholder={filters.country || t('home.text5')}
                  options={countries.data.map(country => ({ label: country.name, value: country.code }))}
                  value={filters.country}
                  setValue={value => onSetFilters({ ...filters, country: value, isApplyButton: false })}
                  onClear={() => onSetFilters({ ...filters, country: '' })}
                  allowEmpty
                />
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.state_ope')}>
                <Menu
                  className={styles.largeSelect}
                  title={t('filters.state_ope')}
                  placeholder={filters.state || t('home.text5')}
                  options={brazilianStates}
                  value={filters.state}
                  setValue={value => onSetFilters({ ...filters, state: value, isApplyButton: false })}
                  onClear={() => onSetFilters({ ...filters, state: '' })}
                  allowEmpty
                />
              </CustomCollapse>
            </>
          )}
        </Scrollbars>
      </Card>
    </div>
  );

  return <div className={styles.container}>{content}</div>;
}
