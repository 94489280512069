import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import styles from './WorldMap.module.scss';
import BrasilImg from 'assets/escudos/brasil.png';

const geoUrl = 'https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json';

const countryData = {
  Brazil: { population: 214, gdp: 1.6 },
  'United States of America': { population: 331, gdp: 23.3 },
  China: { population: 1400, gdp: 17.7 },
  India: { population: 1380, gdp: 3.3 },
  France: { population: 67, gdp: 2.6 },
};

const colorScale = scaleLinear()
  .domain([0, Math.max(...Object.values(countryData).map(d => d.population))])
  .range(['#97C805', '#C80505']);

const WorldMap = () => {
  const [tooltipContent, setTooltipContent] = useState('');
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = event => {
    setTooltipPosition({ x: event.clientX, y: event.clientY - 250 });
  };

  return (
    <div className={styles.container} style={{ position: 'relative', width: '1100px', height: '550px', left: '-20px' }}>
      <ComposableMap projectionConfig={{ scale: 225 }} width={1100} height={600}>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies
              .filter(geo => geo.properties.name !== 'Antarctica')
              .map(geo => {
                const countryName = geo.properties.name;
                const countryInfo = countryData[countryName];

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    className={styles.geography}
                    onClick={event => event.stopPropagation()}
                    onMouseMove={handleMouseMove}
                    onMouseEnter={() => {
                      if (countryInfo) {
                        setTooltipContent(
                          <div>
                            <div style={{ display: 'flex', gap: '5px' }}>
                              <img src={BrasilImg}></img>
                              <p>{geo.properties.name}</p>
                            </div>
                            <div>
                              <p>14 atletas na Liga Principal</p>
                            </div>
                          </div>
                        );
                      } else {
                        setTooltipContent(`${geo.properties.name} - No data`);
                      }
                    }}
                    onMouseLeave={() => setTooltipContent('')}
                    style={{
                      default: {
                        fill: countryInfo ? colorScale(countryInfo.population) : '#97C805',
                        stroke: '#fff',
                        strokeWidth: 0.5,
                      },
                      hover: { fill: '#007aff' },
                      pressed: { fill: countryInfo ? colorScale(countryInfo.population) : '#97C805' },
                    }}
                  />
                );
              })
          }
        </Geographies>
      </ComposableMap>
      {tooltipContent && (
        <div
          className={styles.tooltip}
          style={{
            position: 'absolute',
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
            background: 'white',
            padding: '6px 10px',
            borderRadius: '6px',
            boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
            fontSize: '12px',
            pointerEvents: 'none',
            whiteSpace: 'pre-line',
            zIndex: 10,
          }}
        >
          {tooltipContent}
        </div>
      )}
    </div>
  );
};

export default WorldMap;
