/*eslint-disable eqeqeq*/
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCountries } from 'store/ducks/countries';
import { editAthlete, addAthlete } from 'store/ducks/athleteV2';
import { positionOptions, preferentialFoot } from 'shared/mocks';
import { Dialog, Input, Menu, Calendar, Card, Spinner, Button, Toast } from 'components/template';
import TeamRow from 'components/TeamRow/TeamRow';
import styles from './AthleteDialog.module.scss';
import { MdCalendarMonth } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { FaWikipediaW, FaInstagram, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { Creators as layoutActions } from 'store/ducks/layout';
import SelectTeamDialog from './SelectTeamDialog.js';
import Helpers from 'shared/helpers';

const AthleteDialog = ({ data, isOpen, setIsOpen, setIsAthleteUpdated }) => {
  const dispatch = useDispatch();
  const athlete = data.athlete;
  const countries = useSelector(state => state.countries.countries);
  const { t } = useTranslation();

  const isAddAthlete = athlete.id == null;
  const isCustomAthlete = isAddAthlete || athlete.custom_athlete === true;
  let nationalitiesArr = [];
  athlete.nationalities &&
    athlete.nationalities.length > 0 &&
    athlete.nationalities.map(item => nationalitiesArr.push(item.name));

  const [modifiedAthlete, setModifiedAthlete] = useState(athlete);
  const [athleteNationalities, addAthleteNationalities] = useState(nationalitiesArr);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isDialogTeamOpen, setIsDialogTeamOpen] = useState(false);
  const selectedTeam = modifiedAthlete.selectedTeam ?? athlete.current_team;

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  const onClose = () => {
    setIsOpen(!isOpen);
    setModifiedAthlete(athlete);
    setErrorMsg(null);
    setLoading(false);
    addAthleteNationalities(nationalitiesArr);
  };

  function checkNameIsValid(str) {
    const normalizedStr = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return /^[a-zA-Z\-'".,() ]+$/.test(normalizedStr);
  }

  const onSave = () => {
    let newNationalities = [];

    athleteNationalities.forEach(i => {
      countries.data.forEach(el => {
        if (i === el.name) newNationalities.push(el.code);
      });
    });

    const newAthlete = {
      nickname: modifiedAthlete.nickname,
      birthdate: modifiedAthlete.birthdate,
      height: modifiedAthlete.height ?? '',
      weight: modifiedAthlete.weight ?? '',
      position2: modifiedAthlete.position,
      foot: modifiedAthlete.foot,
      nationalities: newNationalities.join(','),
      female: modifiedAthlete.female,
    };

    const age = (newAthlete['birthdate'] ?? '').length > 0 ? Helpers.getAge(newAthlete['birthdate']) : 0;
    const namesFields = isCustomAthlete
      ? `${modifiedAthlete.nickname}${modifiedAthlete.fullname}`
      : modifiedAthlete.nickname;
    if (!newAthlete['nationalities']) {
      setErrorMsg('athlete_card.error.nationalities');
    } else if (age < 7) {
      setErrorMsg('athlete_card.error.birthdate');
    } else if (!checkNameIsValid(namesFields)) {
      setErrorMsg('athlete_card.error.name');
    } else {
      setErrorMsg(null);
      if (isCustomAthlete) {
        newAthlete.fullname = modifiedAthlete.fullname;
        if (newAthlete.nationalities) {
          newAthlete.country = newNationalities[0];
        } else if (isAddAthlete) {
          newAthlete.nationalities = 'BR';
          newAthlete.country = 'BR';
        }
        if (modifiedAthlete.selectedTeam) {
          newAthlete.team = modifiedAthlete.selectedTeam.id;
        }
        if (newAthlete.height.length === 0) {
          newAthlete.height = 0;
        }
        if (newAthlete.weight.length === 0) {
          newAthlete.weight = 0;
        }
      }

      if (!isAddAthlete) {
        newAthlete.id = modifiedAthlete.id;
      }

      if (modifiedAthlete.wikipedia && modifiedAthlete.wikipedia.length > 0) {
        newAthlete.wikipedia = modifiedAthlete.wikipedia;
      }

      if (modifiedAthlete.instagram && modifiedAthlete.instagram.length > 0) {
        newAthlete.instagram = modifiedAthlete.instagram;
      }

      if (modifiedAthlete.twitter && modifiedAthlete.twitter.length > 0) {
        newAthlete.twitter = modifiedAthlete.twitter;
      }

      if (modifiedAthlete.youtube && modifiedAthlete.youtube.length > 0) {
        newAthlete.youtube = modifiedAthlete.youtube;
      }

      if (modifiedAthlete.photo) {
        const isString = typeof modifiedAthlete.photo === 'string' || modifiedAthlete.photo instanceof String;
        if (!isString) {
          newAthlete.photo = modifiedAthlete.photo;
        }
      }

      setLoading(true);
      const addOrEditAthlete = isAddAthlete ? addAthlete : editAthlete;
      dispatch(addOrEditAthlete(newAthlete)).then(response => {
        setLoading(false);
        if (response != null) {
          onClose();
          setIsAthleteUpdated(true);
        } else {
          dispatch(
            layoutActions.showToast({
              content: t('erros.text18'),
              duration: 3000,
            })
          );
        }
      });
    }
  };

  const handleInputChange = e => {
    e.persist();

    let val = '';
    if (e.target.name == 'height' || e.target.name == 'weight') val = e.target.value.replace(/\D/, '');
    else val = e.target.value;

    setModifiedAthlete({ ...modifiedAthlete, [e.target.name]: val });
  };

  const handleCalendarChange = val => {
    setModifiedAthlete({ ...modifiedAthlete, birthdate: val });
  };

  const inputFileRef = useRef(null);
  const triggerInputFile = () => inputFileRef.current.click();

  const readFile = file => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.addEventListener('load', function(e) {
        resolve(e.target.result);
        setLoading(false);
      });

      reader.readAsDataURL(file);
    });
  };

  const handleFileUpload = e => {
    setLoading(true);
    onUploadFile(e);
  };

  const onUploadFile = async e => {
    const newPhoto = e.target.files[0];
    const photo64 = await readFile(newPhoto);
    setModifiedAthlete(f => ({ ...f, photo: newPhoto, photo64: photo64 }));
  };

  let nationalitiesDiv = '';
  if (countries.loading) {
    nationalitiesDiv = (
      <div className={styles.loader}>
        <Spinner height="24" width="24" />
      </div>
    );
  } else if (countries.error) {
    nationalitiesDiv = (
      <Card softShadow bordered className={styles.errorContainer}>
        <span>{athlete.error}</span>
      </Card>
    );
  } else if (countries.data.length > 0) {
    nationalitiesDiv = (
      <span>
        <Menu
          title={t('athlete_card.nation')}
          className={styles.largeSelect}
          placeholder={t('athlete_card.nation')}
          value={athleteNationalities}
          options={countries.data.map(data => ({ label: data.name, value: data.name }))}
          setValue={val => val.length > 0 && addAthleteNationalities(val)}
          onClear={() => addAthleteNationalities([])}
          multiple
          rounded
          larger
        />
      </span>
    );
  }

  let content = '';
  if (loading) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else {
    content = (
      <>
        {isAddAthlete && (
          <div className={styles.bottomMessage}>
            {t('athlete_card.add_athl_msg1')}
            <br />
            <small>{t('athlete_card.add_athl_msg2')}</small>
          </div>
        )}
        {errorMsg && (
          <Toast
            className={styles.validationMsg}
            isVisible={errorMsg != null}
            content={t(errorMsg)}
            type="danger"
            onClose={() => {}}
          />
        )}
        <div className={styles.content}>
          <div className={styles.athletePhotoDiv}>
            <div
              className={styles.athletePhoto}
              style={{ backgroundImage: `url(${modifiedAthlete.photo64 ?? modifiedAthlete.photo})` }}
            />
            <input type="file" hidden ref={inputFileRef} onChange={handleFileUpload} />
            <Button color="link" noShadow smaller onClick={triggerInputFile} className={styles.editButton}>
              {t('athlete_card.edit_pic')}
            </Button>
            <h5 style={{ paddingTop: isCustomAthlete ? '3px' : '0' }}>Redes Sociais</h5>
            <div className={styles.formGroup}>
              <Input
                rounded
                name="wikipedia"
                icon={<FaWikipediaW />}
                value={modifiedAthlete.wikipedia}
                onChange={e => handleInputChange(e)}
              />
            </div>
            <div className={styles.formGroup}>
              <Input
                rounded
                name="instagram"
                icon={<FaInstagram />}
                value={modifiedAthlete.instagram}
                onChange={e => handleInputChange(e)}
              />
            </div>
            <div className={styles.formGroup}>
              <Input
                rounded
                name="twitter"
                icon={<FaXTwitter />}
                value={modifiedAthlete.twitter}
                onChange={e => handleInputChange(e)}
              />
            </div>
            <div className={styles.formGroup}>
              <Input
                rounded
                name="youtube"
                icon={<FaYoutube />}
                value={modifiedAthlete.youtube}
                onChange={e => handleInputChange(e)}
              />
            </div>
          </div>
          <div className={styles.athleteInfo}>
            <div
              className={styles.formGroup}
              style={{ marginTop: isCustomAthlete ? (isAddAthlete ? '4px' : '0') : '17px' }}
            >
              <label>{t('agencies.club')}</label>
              <span className={styles.teamRow}>
                {selectedTeam ? (
                  <TeamRow name={selectedTeam?.nickname} id={selectedTeam?.id} img={selectedTeam?.emblem} />
                ) : (
                  <small>{t('club.text9')}</small>
                )}
                {isCustomAthlete && (
                  <Button color="link" noShadow smaller onClick={() => setIsDialogTeamOpen(true)}>
                    {t('field.edit')}
                  </Button>
                )}
              </span>
            </div>
            <div className={styles.formGroup}>
              <label>{t('athlete_card.nickname')}</label>
              <span>
                <Input
                  rounded
                  name="nickname"
                  value={modifiedAthlete.nickname}
                  onChange={e => handleInputChange(e)}
                  inputStyle={{ width: 220 }}
                />
              </span>
            </div>
            <div className={styles.formGroup}>
              <label>{t('athlete_card.fullname')}</label>
              <span>
                {isCustomAthlete ? (
                  <Input
                    rounded
                    name="fullname"
                    value={modifiedAthlete.fullname}
                    onChange={e => handleInputChange(e)}
                    inputStyle={{ width: 220 }}
                  />
                ) : (
                  modifiedAthlete.fullname
                )}
              </span>
            </div>
            <div className={styles.formGroup}>
              <label>{t('athlete_card.nation')}</label>
              {nationalitiesDiv}
            </div>
            <div className={styles.formGroup}>
              <label>{t('athlete_card.birth')}</label>
              <div className={styles.calendar}>
                <Calendar
                  name="birthdate"
                  minDate="1975-01-01"
                  maxDate={new Date()}
                  value={modifiedAthlete.birthdate ? new Date(modifiedAthlete.birthdate + ' 00:00') : null}
                  setValue={e => handleCalendarChange(e)}
                />
                <div className={styles.calendarIcon}>
                  <MdCalendarMonth />
                </div>
              </div>
            </div>
            <div className={styles.formGroup}>
              <div className={styles.formGroupHeightWeight}>
                <label>{t('athlete_card.height')}</label>
                <span>
                  <Input
                    type="text"
                    pattern="[0-9]*"
                    rounded
                    name="height"
                    icon={<div className={styles.heightWeightUnit}>cm</div>}
                    value={modifiedAthlete.height?.toString() === '0' ? '' : modifiedAthlete.height}
                    onChange={e => handleInputChange(e)}
                    inputStyle={{ width: 50 }}
                  />
                </span>
              </div>
              <div className={styles.formGroupHeightWeight} style={{ position: 'absolute', right: '19px' }}>
                <span style={{ marginLeft: 0, fontSize: '14px' }}>{t('athlete_card.weight')}</span>
                <span style={{ marginLeft: '10px' }}>
                  <Input
                    rounded
                    name="weight"
                    icon={<div className={styles.heightWeightUnit}>kg</div>}
                    value={modifiedAthlete.weight?.toString() === '0' ? '' : modifiedAthlete.weight}
                    onChange={e => handleInputChange(e)}
                    inputStyle={{ width: 50 }}
                  />
                </span>
              </div>
            </div>
            <div className={styles.formGroup}>
              <label>{t('athlete_card.pos')}</label>
              <span>
                <Menu
                  title={t('athlete_card.pos')}
                  className={styles.large_select_no_scroll}
                  showScroll
                  value={modifiedAthlete.position}
                  setValue={val => setModifiedAthlete({ ...modifiedAthlete, position: val })}
                  onClear={() => setModifiedAthlete({ ...modifiedAthlete, position: '' })}
                  rounded
                  larger
                  options={positionOptions
                    .sort((a, b) => a.value.localeCompare(b.value))
                    .map(position => ({ label: position.label, value: position.label }))}
                />
              </span>
            </div>
            <div className={styles.formGroup}>
              <label>{t('athlete_card.foot')}</label>
              <span>
                <Menu
                  title={t('athlete_card.foot')}
                  className={styles.largeSelect}
                  value={modifiedAthlete.foot}
                  setValue={val => setModifiedAthlete({ ...modifiedAthlete, foot: val })}
                  onClear={() => setModifiedAthlete({ ...modifiedAthlete, foot: '' })}
                  rounded
                  larger
                  options={preferentialFoot.map(foot => ({ label: foot.value, value: foot.value }))}
                />
              </span>
            </div>
            <div className={styles.formGroup}>
              <label>{t('filters.gender')}</label>
              <span>
                <Menu
                  title={t('filters.gender')}
                  className={styles.largeSelect}
                  value={modifiedAthlete.female}
                  setValue={val => setModifiedAthlete({ ...modifiedAthlete, female: val })}
                  onClear={() => setModifiedAthlete({ ...modifiedAthlete, female: false })}
                  rounded
                  larger
                  options={[
                    { label: t('radioAdvancedSearch.male'), value: false },
                    { label: t('radioAdvancedSearch.female'), value: true },
                  ]}
                />
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      mainTitle={t(`athlete_card.${isAddAthlete ? 'add_athl' : 'edit_athl'}`)}
      btnLeft={loading ? '' : t('dialog_content.cancel')}
      btnLeftClick={onClose}
      btnRight={loading ? '' : t('dialog_content.save')}
      btnRightClick={onSave}
      containerClassName={styles.dialogContainer}
    >
      {content}
      {isCustomAthlete && (
        <SelectTeamDialog
          isOpen={isDialogTeamOpen}
          setIsOpen={setIsDialogTeamOpen}
          setTeam={selectedTeam => {
            setModifiedAthlete({ ...modifiedAthlete, selectedTeam });
          }}
        />
      )}
    </Dialog>
  );
};

export default withRouter(AthleteDialog);

AthleteDialog.defaultProps = {
  data: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};
