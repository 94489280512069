/*eslint-disable eqeqeq*/
import React, { useState, useEffect, useRef } from 'react';
import CustomCollapse from './CustomCollapse/CustomCollapse';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash/lang';
import styles from './Filter.module.scss';
import { InputApply, Card, Spinner, Menu, Calendar, Button } from 'components/template';
import { getCountries } from 'store/ducks/countries';
import {
  positionOptions,
  checkboxesAdvancedSearch,
  radioAdvancedSearch,
  brazilianStates,
} from '../../../../shared/mocks';
import CheckboxContainer from './CheckboxContainer/CheckboxContainer';
import RadioContainer from './RadioContainer/RadioContainer';
import { MinMaxDate, MinMaxSeason } from 'pages/AdvancedSearch/Filter/CustomInputs/CustomInputs';
import CheckboxContainerLists from './CheckboxContainerLists/CheckboxContainerLists';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';
import { MdCalendarMonth } from 'react-icons/md';
import { AiOutlineSearch } from 'react-icons/ai';

export default function Filter({ setQueryFilters, onSetQueryFilters, activeFilters, overlay, autoSearch, className }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.data);

  const { t } = useTranslation();

  const countries = useSelector(state => state.countries.countries);

  const [loadingState, setLoadingState] = useState(true);

  const initialFilters = {
    keyword: '',
    profile: [],
    contract: [],
    contractEnds: null,
    position: [],
    nationalities: [],
    foot: '',
    gender: '',
    seasonMatches: {
      season: '',
      min: '',
      max: '',
    },
    seasonGoals: {
      season: '',
      min: '',
      max: '',
    },
    cbfCode: '',
    birthyear: {
      min: '',
      max: '',
    },
    division: [],
    country: '',
    state: '',
    watched: false,
    lists: '',
  };

  const mounted = useRef(false);
  const mountedRedirect = useRef(false);

  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    if (!mountedRedirect.current) {
      mountedRedirect.current = true;
    } else {
      const filtersStorage = getFiltersStorage();
      onSetFilters({ ...filters, ...filtersStorage });
    }
  }, [sessionStorage.redirect]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const filtersFromActive = { ...initialFilters, ...activeFilters };

    if (!isEqual(filtersFromActive, filters)) {
      setFilters(filtersFromActive);
    }
  }, [activeFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoadingState(countries.loading);
  }, [countries.loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (countries.data.length === 0 && !countries.loading) {
      dispatch(getCountries());
    }
  }, [dispatch, countries.data.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;

      const filtersStorage = getFiltersStorage();

      if (filtersStorage) {
        const filtersFromStorage = { ...initialFilters };
        onSetFilters(filtersFromStorage);
      }
    } else {
      if (isEqual(filters, initialFilters)) {
        removeFiltersStorage();
      } else {
        saveFiltersStorage(filters);
      }

      if (autoSearch || (filters.isApplyButton ?? false)) {
        setQueryFilters(queryFiltersParser(filters));
      }
    }
  }, [setQueryFilters, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFiltersStorage = () => {
    return JSON.parse(sessionStorage.getItem('advanced_search'));
  };

  const saveFiltersStorage = filters => {
    sessionStorage.setItem('advanced_search', JSON.stringify(filters));
  };

  const removeFiltersStorage = () => {
    sessionStorage.removeItem('advanced_search');
  };

  const onSetFilters = newFilters => {
    const isApplyButton = newFilters.isApplyButton ?? false;
    if (isApplyButton || !isEqual(newFilters, filters)) {
      setFilters(newFilters);
      if (autoSearch || isApplyButton) {
        onSetQueryFilters({ ...newFilters });
      }
    }
  };

  const professionalFilterParser = arr => {
    if (arr.length === 1) {
      if (arr[0] === 'atletas-profissionais') {
        return true;
      } else if (arr[0] === 'atletas-amadores') {
        return false;
      }
    }

    return null;
  };

  const contractFilterParser = arr => {
    if (arr.length === 1) {
      if (arr[0] === 'com-contrato') {
        return true;
      } else if (arr[0] === 'sem-contrato') {
        return false;
      }
    }

    return null;
  };

  const queryFiltersParser = filters => {
    const params = {
      q: filters.keyword,
      professional: professionalFilterParser(filters.profile.map(item => item)),
      has_contract: contractFilterParser(filters.contract.map(item => item)),
      contract_ends: filters.contractEnds,
      position: filters.position,
      nationalities: filters.nationalities,
      foot: filters.foot,
      female: user.permission_gender === 'female' ? true : filters.gender === 'female',
      season_matches: filters.seasonMatches.season,
      min_matches_in_season: filters.seasonMatches.min,
      max_matches_in_season: filters.seasonMatches.max,
      season_goals: filters.seasonGoals.season,
      min_goals_in_season: filters.seasonGoals.min,
      max_goals_in_season: filters.seasonGoals.max,
      min_birthyear: filters.birthyear.min,
      max_birthyear: filters.birthyear.max,
      cbf_code: filters.cbfCode,
      division: filters.division,
      team_country: filters.country,
      team_state: filters.state,
      watched: filters.watched,
      lists: filters.lists,
    };

    return params;
  };

  const [calendarDisplay, setCalendarDisplay] = useState(false);

  let content;
  content = (
    <div>
      {overlay && <div className={styles.overlay} />}
      <Card borderedGrey className={className}>
        <Scrollbars
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: '10px',
                height: '160px !important',
                borderRadius: '5px',
                backgroundColor: '#D7D6D2',
                right: '5px',
              }}
            />
          )}
          renderThumbHorizontal={({ style, ...props }) => (
            <div {...props} style={{ ...style, borderRadius: '5px', backgroundColor: '#D7D6D2' }} />
          )}
        >
          {loadingState ? (
            <div className={styles.loader}>
              <Spinner />
            </div>
          ) : (
            <>
              <CustomCollapse openedDefault title={t('filters.key')}>
                <div className={styles.filterInnerContainer2}>
                  <input
                    className={styles.filterInput}
                    onChange={e => onSetFilters({ ...filters, keyword: e.target.value, isApplyButton: false })}
                  />
                  <div
                    className={styles.svgContainer2}
                    onClick={() => onSetFilters({ ...filters, isApplyButton: true })}
                  >
                    <AiOutlineSearch />
                    <Button
                      className={styles.applyButton}
                      onClick={() => onSetFilters({ ...filters, isApplyButton: true })}
                      rounded
                      color="green"
                      smaller="true"
                    >
                      {t('proj.applyFilters')}
                    </Button>
                  </div>
                </div>
                <Button
                  className={styles.clearButton}
                  onClick={() => setFilters(initialFilters)}
                  rounded
                  color="green"
                  smaller="true"
                >
                  {t('filters.clean_filters')}
                </Button>
              </CustomCollapse>
              {user.permission_gender === 'general' && (
                <CustomCollapse openedDefault title={t('filters.gender')}>
                  <RadioContainer
                    setFilter={value => onSetFilters({ ...filters, gender: value, isApplyButton: false })}
                    value={filters.gender || (user.permission_gender !== 'female' ? 'male' : 'female')}
                    options={radioAdvancedSearch.gender.map(item => ({
                      label: t(`radioAdvancedSearch.${item.value}`),
                      value: item.value,
                    }))}
                    name={t('filters.gen')}
                  />
                </CustomCollapse>
              )}
              <CheckboxContainerLists
                setFilter={(listsIds, watched) =>
                  onSetFilters({ ...filters, lists: listsIds, watched: watched, isApplyButton: true })
                }
                listsIds={filters.lists || ''}
                watched={filters.watched || false}
              />
              <CustomCollapse openedDefault title={t('filters.profile')}>
                <CheckboxContainer
                  setFilter={value => onSetFilters({ ...filters, profile: value, isApplyButton: false })}
                  value={filters.profile || null}
                  checkboxes={checkboxesAdvancedSearch.profile.map(item => ({
                    label: t(`profileOptions.${item.value}`),
                    value: item.value,
                  }))}
                />
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.contract')}>
                <CheckboxContainer
                  setFilter={value => onSetFilters({ ...filters, contract: value, isApplyButton: false })}
                  value={filters.contract || null}
                  checkboxes={checkboxesAdvancedSearch.contract.map(item => ({
                    label: t(`contractOptions.${item.value}`),
                    value: item.value,
                  }))}
                />
                <div className={styles.contractDate}>
                  <span>{t('filters.contract_until')}</span>
                  <div
                    className={styles.calendar_before}
                    style={{ display: calendarDisplay ? 'none' : 'flex' }}
                    onClick={() => setCalendarDisplay(true)}
                  >
                    {t('transferMarket.select')}...
                  </div>
                  <div className={styles.calendar} style={{ display: calendarDisplay ? 'flex' : 'none' }}>
                    <Calendar
                      value={filters.contractEnds}
                      setValue={value => onSetFilters({ ...filters, contractEnds: value, isApplyButton: false })}
                    />
                    <div className={styles.calendarIcon}>
                      <MdCalendarMonth />
                    </div>
                  </div>
                </div>
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.pos')}>
                <CheckboxContainer
                  setFilter={value => onSetFilters({ ...filters, position: value, isApplyButton: false })}
                  value={filters.position || null}
                  checkboxes={positionOptions.map(item => ({
                    label: t(`positionOptions.${item.value}`),
                    value: item.label,
                  }))}
                />
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.nation')}>
                <CheckboxContainer
                  setFilter={value => onSetFilters({ ...filters, nationalities: value, isApplyButton: false })}
                  value={filters.nationalities || null}
                  checkboxes={countries.data.map(country => ({
                    label: country.name === 'Madagáscar' ? 'Madagascar' : country.name,
                    value: country.code,
                  }))}
                />
              </CustomCollapse>
              <CustomCollapse customStyle={styles.collapseWithMessage} openedDefault title={t('filters.games')}>
                <div className={styles.menuMinMaxContainer}>
                  <MinMaxSeason
                    setFilter={value => onSetFilters({ ...filters, seasonMatches: value, isApplyButton: false })}
                    onClear={() =>
                      onSetFilters({
                        ...filters,
                        seasonMatches: {
                          season: '',
                          min: '',
                          max: '',
                        },
                      })
                    }
                    value={filters.seasonMatches}
                  />
                </div>
              </CustomCollapse>
              <CustomCollapse customStyle={styles.collapseWithMessage} openedDefault title={t('filters.gols')}>
                <div className={styles.menuMinMaxContainer}>
                  <MinMaxSeason
                    setFilter={value => onSetFilters({ ...filters, seasonGoals: value, isApplyButton: false })}
                    onClear={() =>
                      onSetFilters({
                        ...filters,
                        seasonGoals: {
                          season: '',
                          min: '',
                          max: '',
                        },
                      })
                    }
                    value={filters.seasonGoals}
                  />
                </div>
              </CustomCollapse>
              {/* <CustomCollapse openedDefault title='Atleta Monitorado'>
                                <CheckboxContainer checkboxes={checkboxesAdvancedSearch.monitoredAthlete} />
                            </CustomCollapse> */}
              <CustomCollapse openedDefault title={t('filters.cbf_num')}>
                <div className={styles.inputNumCBF}>
                  <InputApply
                    value={filters.cbfCode}
                    onClick={value => onSetFilters({ ...filters, cbfCode: value, isApplyButton: false })}
                  />
                </div>
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.birt_year')}>
                {/* <div className={styles.MinMaxDate}> */}
                <div className={styles.minMaxDateChildren}>
                  <MinMaxDate
                    from
                    title={t('slider_menu.in')}
                    titlePlaceholder={
                      (filters?.birthyear?.min ?? '').toString().length > 0 ? filters.birthyear.min : '---'
                    }
                    setFilter={values =>
                      onSetFilters({
                        ...filters,
                        birthyear: {
                          ...filters.birthyear,
                          min: values.min,
                        },
                        isApplyButton: false,
                      })
                    }
                    onClear={() => onSetFilters({ ...filters, birthyear: { ...filters.birthyear, min: '' } })}
                    valueMin={filters.birthyear.min}
                    valueMax={filters.birthyear.max}
                  />
                </div>
                <div className={styles.minMaxDateChildren}>
                  <MinMaxDate
                    to
                    title={t('slider_menu.e')}
                    titlePlaceholder={
                      (filters?.birthyear?.max ?? '').toString().length > 0 ? filters.birthyear.max : '---'
                    }
                    setFilter={values =>
                      onSetFilters({
                        ...filters,
                        birthyear: {
                          ...filters.birthyear,
                          max: values.max,
                        },
                        isApplyButton: false,
                      })
                    }
                    onClear={() => onSetFilters({ ...filters, birthyear: { ...filters.birthyear, max: '' } })}
                    valueMin={filters.birthyear.min}
                    valueMax={filters.birthyear.max}
                  />
                </div>
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.division_ope')}>
                <CheckboxContainer
                  setFilter={value => onSetFilters({ ...filters, division: value, isApplyButton: false })}
                  value={filters.division || null}
                  checkboxes={checkboxesAdvancedSearch.newDivision.map(item => ({
                    label: t(`newDivisionOptions.${item.id}`),
                    value: item.value,
                  }))}
                />
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.country_ope')}>
                <Menu
                  className={styles.largeSelect}
                  title={t('filters.country_ope')}
                  placeholder={filters.country || 'Todos'}
                  options={countries.data.map(country => ({ label: country.name, value: country.code }))}
                  value={filters.country}
                  setValue={value => onSetFilters({ ...filters, country: value, isApplyButton: false })}
                  onClear={() => onSetFilters({ ...filters, country: '' })}
                  allowEmpty
                />
              </CustomCollapse>
              <CustomCollapse openedDefault title={t('filters.state_ope')}>
                <Menu
                  className={styles.largeSelect}
                  title={t('filters.state_ope')}
                  placeholder={filters.state || 'Todos'}
                  options={brazilianStates}
                  value={filters.state}
                  setValue={value => onSetFilters({ ...filters, state: value, isApplyButton: false })}
                  onClear={() => onSetFilters({ ...filters, state: '' })}
                  allowEmpty
                />
              </CustomCollapse>
              {/* <CustomCollapse openedDefault title='Possui passaporte europeu'>
                                <CheckboxContainer checkboxes={checkboxesAdvancedSearch.europeanPassport} />
                            </CustomCollapse> */}
            </>
          )}
        </Scrollbars>
      </Card>
    </div>
  );

  return <div className={styles.container}>{content}</div>;
}
