import React from 'react';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
//import "../../../translations/i18n"

import styles from './ActiveFilters.module.scss';
import { brazilianStates, checkboxesAdvancedSearch } from 'shared/mocks';

const ActiveFilters = ({ setActiveFilters, activeFilters, countries, overlay }) => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user.data);

  const defaultFiltersValues = {
    gender: user.permission_gender !== 'female' ? 'male' : 'female',
    custom_athletes: 'normal',
  };

  const genderIsNotDefined = activeFilters['gender'] == null || activeFilters['gender'] === '';
  const customAthleteIsNotDefined = activeFilters['custom_athletes'] == null || activeFilters['custom_athletes'] === '';
  if (genderIsNotDefined || customAthleteIsNotDefined) {
    let newActiveFilters = { ...activeFilters };
    if (genderIsNotDefined) {
      newActiveFilters['gender'] = defaultFiltersValues['gender'];
    }
    if (customAthleteIsNotDefined) {
      newActiveFilters['custom_athletes'] = defaultFiltersValues['custom_athletes'];
    }
    setActiveFilters(newActiveFilters);
  }

  const divisionsObj = {
    '1': t('newDivisionOptions.UmDivisao'),
    '2': t('newDivisionOptions.DoisDivisao'),
    '3': t('newDivisionOptions.TresDivisao'),
    '4': t('newDivisionOptions.QuatroDivisao'),
    '0': t('newDivisionOptions.SemDivisao'),
  };

  const removeFilter = filter => {
    let newActiveFilters = { ...activeFilters };

    const filterType = filter.filter;

    if (Array.isArray(newActiveFilters[filterType])) {
      let newArr = newActiveFilters[filterType].filter(item => item !== filter.value);

      if (newArr.length === 0) {
        delete newActiveFilters[filterType];
      } else {
        newActiveFilters[filterType] = newArr;
      }
    } else {
      delete newActiveFilters[filterType];
    }

    setActiveFilters(newActiveFilters);
  };

  const getFilterMessage = (filter, value) => {
    if (filter === 'lists' || filter === 'watched') {
      return [
        {
          message: t('filters.lists'),
          key: value,
          value: value,
          filter,
        },
      ];
    }

    if (typeof value === 'string') {
      if (filter === 'gender') {
        return [
          {
            message: value === 'male' ? t('user.text16') : t('text.box33'),
            key: value,
            value: value,
            filter,
          },
        ];
      }
      if (filter === 'custom_athletes') {
        return [
          {
            message: value === 'custom' ? t('filters.custom_athletes_applied') : t('filters.custom_athletes_unapplied'),
            key: value,
            value: value,
            filter,
          },
        ];
      }
    }

    if (Array.isArray(value)) {
      return value.map(item => {
        if (filter === 'nationalities') {
          return {
            message: ` ${t('athlete_card.nation')} ${countries?.find(country => country.code === item)?.name}`,
            key: item,
            value: item,
            filter,
          };
        } else if (filter === 'position') {
          return {
            message: t(`positionOptionsRaw.${item}`),
            key: item,
            value: item,
            filter,
          };
        } else if (filter === 'profile') {
          return {
            message:
              item === 'atletas-profissionais'
                ? t('profileOptions.atletas-profissionais')
                : t('profileOptions.atletas-amadores'),
            key: item,
            value: item,
            filter,
          };
        } else if (filter === 'contract') {
          return {
            message: item === 'com-contrato' ? t('contractOptions.com-contrato') : t('contractOptions.sem-contrato'),
            key: item,
            value: item,
            filter,
          };
        } else if (filter === 'division') {
          return {
            message: divisionsObj[item],
            key: item,
            value: item,
            filter,
          };
        } else {
          return {
            message: checkboxesAdvancedSearch[filter]?.find(val => t(`positionOptions${val.value === item}`))?.label,
            key: item,
            value: item,
            filter,
          };
        }
      });
    }

    if (typeof value === 'object') {
      switch (filter) {
        case 'seasonMatches': {
          return [
            {
              message: `
                ${
                  value.max && value.min
                    ? `${t('filters.mini')} ${value.min} ${t('filters.and_max')} ${value.max}`
                    : `${value.min && `${t('filters.mini')} ${value.min}`} 
                  ${value.max && ` ${t('filters.max')} ${value.max}`}`
                } 
                ${t('filters.games_in')} 
                ${value.season}`,
              key: `${filter}${value.season}`,
              value,
              filter,
            },
          ];
        }
        case 'seasonGoals': {
          return [
            {
              message: `
                ${
                  value.max && value.min
                    ? `${t('filters.mini')} ${value.min} ${t('filters.and_max')} ${value.max}`
                    : `${value.min && `${t('filters.mini')} ${value.min}`} 
                  ${value.max && ` ${t('filters.max')} ${value.max}`}`
                } 
                ${t('filters.gols')}
                ${value.season}`,
              key: `${filter}${value.season}`,
              value,
              filter,
            },
          ];
        }
        case 'age': {
          return [
            {
              message: `${
                value.min && value.max
                  ? `${t('slider_menu.in')} ${value.min} ${t('slider_menu.e')} ${value.max}`
                  : `${value.min ? `${t('filters.mini')} ${value.min}` : `${t('filters.ate')} ${value.max}`}`
              } ${t('athlete_card.idade')}`,
              key: `${filter}${value.season}`,
              value,
              filter,
            },
          ];
        }
        case 'birthyear': {
          return [
            {
              message: `${
                value.min && value.max
                  ? `${t('filters.de')} ${value.min} ${t('filters.a')} ${value.max}`
                  : `${value.min ? `${t('filters.mini')} ${value.min}` : `${t('filters.ate')} ${value.max}`}`
              }`,
              key: `${filter}${value.season}`,
              value,
              filter,
            },
          ];
        }
        default:
          return [];
      }
    }

    const dateParser = dateString => {
      var date = new Date(dateString);

      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    };

    const filterMessages = {
      keyword: `${t('filters.key')} ${value}`,
      country: countries?.find(country => country.code === value)?.name,
      state: brazilianStates?.find(state => state.value === value)?.label,
      foot: `${t('athlete_card.foot')}: ${t(`preferentialFoot.${value}`)}`,
      cbfCode: `${t('filters.cbf_num')} ${value}`,
      contractEnds: `${t('filters.contract_until')} ${dateParser(value)}`,
    };

    return [
      {
        message: filterMessages[filter],
        key: filter,
        value,
        filter,
      },
    ];
  };

  const defaultFilters = [];
  const anotherFilters = [];
  for (const key in activeFilters) {
    if (key !== 'isApplyButton') {
      if (key === 'custom_athletes' || key === 'gender') {
        defaultFilters.push(...getFilterMessage(key, activeFilters[key]));
      } else {
        anotherFilters.push(...getFilterMessage(key, activeFilters[key]));
      }
    }
  }

  return (
    <div className={styles.activeFilters}>
      {overlay && <div className={styles.overlay} />}
      <span>{t('filters.active_filters')}</span>
      {defaultFilters.map(filter => (
        <span key={filter.key} className={styles.badge}>
          <span>{filter.message}</span>
        </span>
      ))}
      {anotherFilters.map(filter => (
        <span key={filter.key} className={styles.badge}>
          <span>{filter.message}</span>
          <MdClose className={styles.deleteIcon} onClick={() => removeFilter(filter)} />
        </span>
      ))}
      {anotherFilters.length > 0 && (
        <span
          className={styles.removeFilters}
          onClick={() => setActiveFilters({ ...defaultFiltersValues, isApplyButton: true })}
        >
          {t('rest.box22')}
        </span>
      )}
    </div>
  );
};

export default ActiveFilters;
