import React, { useEffect } from 'react';
import styles from './StepOne.module.scss';
import { Calendar, Input, Menu, Radio, Spinner } from 'components/template';
import { useTranslation } from 'react-i18next';
import { positionOptions } from 'shared/mocks';
import { getCountries } from 'store/ducks/countries';
import { useDispatch, useSelector } from 'react-redux';
import { MdCalendarMonth } from 'react-icons/md';
import moment from 'moment';

export default function StepOneDesiredAthlete({ adsData, setAdsData }) {
  const { t } = useTranslation();
  const countries = useSelector(state => state.countries.countries);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.data);

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  return (
    <div className={styles.stepOneBody}>
      <div className={styles.stepOneText}>
        <span>{t('transferMarket.step1title')}</span>
      </div>
      <div className={styles.stepOneNegotiation}>
        <div className={styles.stepOneNegotiationSpan}>
          <span>{'Perfil'} *</span>
        </div>
        <div className={styles.stepOneNegotiationSelect}>
          <Radio
            label={t('compe.text26')}
            checked={adsData.amateur === false}
            onChange={() => {
              setAdsData({
                ...adsData,
                amateur: false,
              });
            }}
          />
          <Radio
            label={t('user.text17')}
            checked={adsData.amateur === true}
            onChange={() => {
              setAdsData({
                ...adsData,
                amateur: true,
              });
            }}
          />
        </div>
      </div>
      {user.permission_gender === 'general' && (
        <div className={styles.stepOneNegotiation}>
          <div className={styles.stepOneNegotiationSpan}>
            <span>{t('filters.gender')} *</span>
          </div>
          <div className={styles.stepOneNegotiationSelect}>
            <Radio
              label={t('radioAdvancedSearch.male')}
              checked={adsData.female === false}
              onChange={() => {
                setAdsData({
                  ...adsData,
                  female: false,
                });
              }}
            />
            <Radio
              label={t('radioAdvancedSearch.female')}
              checked={adsData.female === true}
              onChange={() => {
                setAdsData({
                  ...adsData,
                  female: true,
                });
              }}
            />
          </div>
        </div>
      )}
      <div className={styles.stepOneSale}>
        <div className={styles.stepOneSaleSpan}>
          <span>{t('athlete_card.pos')} *</span>
        </div>
        <Menu
          className={styles.largeSelect}
          title={t('athlete_card.pos')}
          placeholder={t('athlete_card.pos')}
          options={positionOptions.map(item => ({ label: t(`positionOptions.${item.value}`), value: item.label }))}
          value={adsData.position}
          setValue={value => setAdsData({ ...adsData, position: value })}
        />
      </div>
      <div className={styles.stepOneNegotiation}>
        <div className={styles.stepOneNegotiationSpan}>
          <span>{t('athlete_card.foot')}</span>
        </div>
        <div className={styles.stepOneNegotiationSelect}>
          <Radio
            label={t('foot.right')}
            checked={adsData.foot === 'Direito'}
            onChange={() => {
              setAdsData({
                ...adsData,
                foot: 'Direito',
              });
            }}
          />
          <Radio
            label={t('foot.left')}
            checked={adsData.foot === 'Esquerdo'}
            onChange={() => {
              setAdsData({
                ...adsData,
                foot: 'Esquerdo',
              });
            }}
          />
          <Radio
            label={t('foot.ambidextrous')}
            checked={adsData.foot === 'Ambidestro'}
            onChange={() => {
              setAdsData({
                ...adsData,
                foot: 'Ambidestro',
              });
            }}
          />
          <Radio
            label={t('foot.indifferent')}
            checked={adsData.foot === 'Indiferente'}
            onChange={() => {
              setAdsData({
                ...adsData,
                foot: 'Indiferente',
              });
            }}
          />
        </div>
      </div>
      <div className={styles.stepOneMaxMinAge}>
        <div className={styles.stepOneMaxMinAgeSpan}>
          <span>{t('athlete_list.box3')} *</span>
        </div>
        <small>{t('filters.min_sm')}</small>
        <div>
          <Input
            inputClassName={styles.stepOneMaxMinAgeInput}
            value={adsData.min_age}
            onChange={e => {
              setAdsData({
                ...adsData,
                min_age: e.target.value.replace(/[^0-9]/g, ''),
              });
            }}
          />
        </div>
        <small>{t('filters.max_sm')}</small>
        <div>
          <Input
            inputClassName={styles.stepOneMaxMinAgeInput}
            value={adsData.max_age}
            onChange={e => {
              setAdsData({
                ...adsData,
                max_age: e.target.value.replace(/[^0-9]/g, ''),
              });
            }}
          />
        </div>
      </div>
      <div className={styles.stepOneSale}>
        <div className={styles.stepOneSaleSpan}>
          <span>{t('filters.nation')}</span>
        </div>
        {countries.loading ? (
          <Spinner height="24" width="24" />
        ) : (
          <Menu
            className={styles.largeSelect}
            title={t('filters.nation')}
            placeholder={t('filters.nation')}
            options={countries.data.map(data => ({ label: data.name, value: data.name }))}
            value={adsData.nationalities.map(n => n.name)}
            setValue={val =>
              setAdsData({ ...adsData, nationalities: val.map(n => countries.data.find(c => c.name === n)) })
            }
            onClear={() => setAdsData({ ...adsData, nationalities: [] })}
            multiple
            allowEmpty
          />
        )}
      </div>
      <div className={styles.stepOneSale}>
        <div className={styles.stepOneSaleSpan}>
          <span>{t('filters.contract')}</span>
        </div>
        <Radio
          className={styles.stepOneCheckbox}
          label={t('contractOptions.sem-contrato')}
          checked={adsData.has_contract === false}
          onChange={() => {
            setAdsData({
              ...adsData,
              has_contract: false,
              contract_end: null,
            });
          }}
        />
        <Radio
          className={styles.stepOneCheckbox}
          label={t('contractOptions.com-contrato')}
          checked={adsData.has_contract === true}
          onChange={() => {
            setAdsData({
              ...adsData,
              has_contract: true,
              contract_end: moment().format('YYYY-MM-DD'),
            });
          }}
        />
        {adsData.contract_end && (
          <>
            <small>{t('filters.contract_until')}</small>
            <div className={styles.calendarInput}>
              <Calendar
                value={adsData.contract_end}
                setValue={value =>
                  setAdsData({
                    ...adsData,
                    contract_end: value,
                  })
                }
              />
              <div className={styles.calendarIcon}>
                <MdCalendarMonth />
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.stepOneObservation}>
        <div className={styles.stepOneObservationSpan}>
          <span>{t('transferMarket.moreCharacteristics')}</span>
        </div>
        <div>
          <textarea
            className={styles.stepOneObservationInput}
            value={adsData.characteristics}
            onChange={e =>
              setAdsData({
                ...adsData,
                characteristics: e.target.value,
              })
            }
          ></textarea>
        </div>
      </div>
    </div>
  );
}
