import React, { useState } from 'react';
import styles from './Overview.module.scss';
import { useTranslation } from 'react-i18next';
import WorldMap from './WorldMap/WorldMap';
import VerticalColorScale from './VerticalColorScale/VerticalColorScale';
import Ranking from './Ranking/Ranking';
import ActionBar from './ActionBar/ActionBar';

const initialFilters = {
  seasons: [],
  categories: [],
  context: [],
  countries: [],
  loading: true,
};

export default function Overview() {
  const { t } = useTranslation();
  const [filters, setFilters] = useState(initialFilters);
  const [selectedFilters, setSelectedFilters] = useState({
    category: null,
    context: '',
    country: '',
    name: '',
  });

  return (
    <div className={styles.container}>
      <div className={styles.titulo}>
        <h1>Visão geral de atletas com formação no clube</h1>
        <p>Estamos monitorando a situação de 1.246 atletas com formação no clube Footure FC, atuando em 78 países.</p>
        <div className={styles.filterBar}>
          <ActionBar filters={filters} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
        </div>
      </div>
      <div className={styles.content}>
        <VerticalColorScale min={0} max={2000} />
        <WorldMap />
        <Ranking />
      </div>
    </div>
  );
}
